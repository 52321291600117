<template>
  <div fluid class="content-wrapper" style="padding: 2.1rem 2rem 0">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">{{ $t("RateAndInventory.add_rateplan_fix") }}</h4>
      <v-btn
        icon
        class="ml-3"
        @click="initAll()"
        :class="loadingOldData && 'animate__spining'"
        :disabled="loadingOldData"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <div class="row match-height">
      <div v-if="loadingOldData" class="col-md-12 p-md-3">
        <v-skeleton-loader
          type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, actions"
        ></v-skeleton-loader>
      </div>
      <div v-else class="col-md-12 p-md-3">
        <div class="content-body">
          <section id="timeline" class="timeline-left timeline-wrapper">
            <ul class="timeline">
              <li class="timeline-line"></li>
              <!-- 1 -->
              <li class="timeline-item">
                <div class="timeline-badge">
                  <span
                    class="bg-red bg-lighten-1 d-flex align-center justify-center"
                    data-toggle="tooltip"
                    data-placement="right"
                    title
                    data-original-title
                  >
                    <b class="text-white">1</b>
                  </span>
                </div>
                <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                  <div class="card-header">
                    <h4 class="card-title">
                      {{ $t("RateAndInventory.add_rateplan_name") }}
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="card-body">
                      <div class="form-group validate d-flex flex-column">
                        <div class="controls">
                          <input
                            type="text"
                            id="name"
                            class="form-control"
                            :class="
                              (!name || rateplanID || dup_rateplan_name_value) && 'mb-1'
                            "
                            style="min-height: 3rem"
                            :placeholder="$t('RateAndInventory.rateplan_name')"
                            name
                            v-model="name"
                            @focus="getfocus('name')"
                            @change="getfocus('name')"
                            @mouseleave="mouseOver('name')"
                          />
                        </div>
                        <span class="error-text-input" v-if="!name">
                          {{ $t("Alert.pleasefilldata") }}
                        </span>
                        <span class="danger-text-input" v-if="dup_rateplan_name_value">
                          *{{ $t("RateAndInventory.dup_rateplan_name")
                          }}{{
                            dup_both_per
                              ? ` (${
                                  dup_both_per
                                    ? $t("RateAndInventory.dup_both_per")
                                    : per_room
                                    ? $t("RateAndInventory.add_rateplan_room")
                                    : $t("RateAndInventory.add_rateplan_person")
                                })`
                              : ""
                          }}{{ sum_dup_rateplan_name && `(${sum_dup_rateplan_name})` }}
                        </span>
                        <span class="warn-text-input" v-if="rateplanID">
                          *{{ $t("RateAndInventory.warn_old_rateplan_name") }}:
                          {{ old_rateplan_name }} ({{
                            dup_both_per
                              ? $t("RateAndInventory.dup_both_per")
                              : per_room
                              ? $t("RateAndInventory.add_rateplan_room")
                              : $t("RateAndInventory.add_rateplan_person")
                          }})
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 2 -->
              <li class="timeline-item">
                <div class="timeline-badge">
                  <span
                    class="bg-red bg-lighten-1 d-flex align-center justify-center"
                    data-toggle="tooltip"
                    data-placement="right"
                    title
                    data-original-title
                  >
                    <b class="text-white">2</b>
                  </span>
                </div>
                <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                  <div class="card-header">
                    <h4 class="card-title">
                      {{ $t("RateAndInventory.add_rateplan_advance") }}
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="card-body">
                      <div class="form-group validate">
                        <div class="controls">
                          <div
                            class="dropdown select"
                            @click="getfocus('advance')"
                            @mouseleave="mouseOver('advance')"
                          >
                            <a id="advance">
                              <v-select
                                v-model="advance_booking"
                                :items="itemsAdvanceBooking"
                                item-text="name"
                                item-value="value"
                                label
                                :placeholder="$t('SignupStep.select_one')"
                                :no-data-text="$t('SignupStep.nodata')"
                                color="#1e9ff2"
                                solo
                                flat
                                dense
                                single-line
                                hide-details
                              >
                                <template v-slot:item="data">
                                  <v-list-item-content
                                    @mouseenter="getfocus('advance')"
                                    @mouseleave="mouseOver('advance')"
                                  >
                                    <v-list-item-title
                                      v-html="data.item.name"
                                      class="title-signup-sj"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-select>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 3 -->
              <li class="timeline-item">
                <div class="timeline-badge">
                  <span
                    class="bg-red bg-lighten-1 d-flex align-center justify-center"
                    data-toggle="tooltip"
                    data-placement="right"
                    title
                    data-original-title
                  >
                    <b class="text-white">3</b>
                  </span>
                </div>
                <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                  <div class="card-header">
                    <h4 class="card-title">
                      {{ $t("RateAndInventory.add_rateplan_change_date") }}
                    </h4>
                  </div>
                  <div class="alert alert-warning ml-2 mb-2 w-75" role="alert">
                    <v-icon small color="#963b00">mdi-alert-circle-outline</v-icon>
                    {{ $t("RateAndInventory.add_rateplan_change_date_warning") }}
                  </div>
                  <div class="card-content">
                    <div class="card-body">
                      <div class="form-group">
                        <div class="controls">
                          <v-radio-group v-model="change_date">
                            <v-radio
                              :label="$t('RateAndInventory.add_rateplan_change_date3')"
                              :value="false"
                              color="#1e9ff2"
                            ></v-radio>
                            <v-radio
                              v-for="n in itemsChangeDate"
                              :key="n.name"
                              :label="n.name"
                              :value="n.value"
                              color="#1e9ff2"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 4 -->
              <li class="timeline-item">
                <div class="timeline-badge">
                  <span
                    class="bg-red bg-lighten-1 d-flex align-center justify-center"
                    data-toggle="tooltip"
                    data-placement="right"
                    title
                    data-original-title
                  >
                    <b class="text-white">4</b>
                  </span>
                </div>
                <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                  <div class="card-header">
                    <h4 class="card-title">
                      {{ $t("RateAndInventory.add_rateplan_privileges") }}
                    </h4>
                  </div>
                  <div class="alert alert-warning ml-2 mb-2 w-75" role="alert">
                    <v-icon small color="#963b00">mdi-alert-circle-outline</v-icon>
                    {{ $t("RateAndInventory.add_rateplan_privilegess") }}
                  </div>
                  <div class="card-content">
                    <div class="card-body">
                      <div class="form-group">
                        <div class="controls">
                          <v-radio-group v-model="privileges">
                            <v-radio
                              :label="$t('RateAndInventory.add_rateplan_privileges1')"
                              :value="false"
                              color="#1e9ff2"
                            ></v-radio>
                            <v-radio
                              :label="$t('RateAndInventory.add_rateplan_privileges2')"
                              :value="true"
                              color="#1e9ff2"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 5 -->
              <li class="timeline-item">
                <div class="timeline-badge">
                  <span
                    class="bg-red bg-lighten-1 d-flex align-center justify-center"
                    data-toggle="tooltip"
                    data-placement="right"
                    title
                    data-original-title
                  >
                    <b class="text-white">5</b>
                  </span>
                </div>
                <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                  <div class="card-header">
                    <h4 class="card-title">
                      {{ $t("RateAndInventory.add_rateplan_food_service") }}
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="card-body">
                      <div class="form-group">
                        <div class="controls">
                          <v-radio-group v-model="food_service">
                            <v-radio
                              :label="$t('RateAndInventory.add_rateplan_food_service1')"
                              :value="false"
                              color="#1e9ff2"
                            ></v-radio>
                            <v-radio
                              :label="$t('RateAndInventory.add_rateplan_food_service2')"
                              :value="true"
                              color="#1e9ff2"
                            ></v-radio>
                          </v-radio-group>
                          <div class="row pt-1 pl-3" id="choice5more" v-if="food_service">
                            <v-radio-group
                              v-model="food_service_select"
                              class="mt-0 ml-15"
                            >
                              <v-radio
                                v-for="n in itemsFoodService"
                                :key="n.name"
                                :label="n.name"
                                :value="n.value"
                                color="#1e9ff2"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 6 -->
              <li class="timeline-item">
                <div class="timeline-badge">
                  <span
                    class="bg-red bg-lighten-1 d-flex align-center justify-center"
                    data-toggle="tooltip"
                    data-placement="right"
                    title
                    data-original-title
                  >
                    <b class="text-white">6</b>
                  </span>
                </div>
                <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                  <div class="card-header">
                    <h4 class="card-title">
                      {{ $t("RateAndInventory.add_rateplan_package") }}
                    </h4>
                  </div>
                  <div class="alert alert-warning ml-2 mb-2 w-75" role="alert">
                    <v-icon small color="#963b00">mdi-alert-circle-outline</v-icon>
                    {{ $t("RateAndInventory.add_rateplan_package_warning") }}
                  </div>
                  <div class="card-content">
                    <div class="card-body">
                      <div class="form-group">
                        <div class="controls">
                          <v-radio-group v-model="package_value">
                            <v-radio
                              :label="$t('RateAndInventory.add_rateplan_package1')"
                              :value="false"
                              color="#1e9ff2"
                            ></v-radio>
                            <v-radio
                              :label="$t('RateAndInventory.add_rateplan_package2')"
                              :value="true"
                              color="#1e9ff2"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                      <div class="row pl-lg-3" id="choice6more" v-if="package_value">
                        <div class="col-md-12 py-0" v-if="package.length">
                          <span class="warn-text-input">
                            *{{ $t("RateAndInventory.type_rateplan_per") }}:
                            {{
                              per_room
                                ? $t("RateAndInventory.add_rateplan_room")
                                : $t("RateAndInventory.add_rateplan_person")
                            }}
                          </span>
                        </div>
                        <div class="col-md-12">
                          <div
                            class="card"
                            style="
                              box-shadow: none;
                              border: 1px solid #63e1b4;
                              border-radius: 0.45rem 0.45rem 0 0;
                            "
                          >
                            <div class="card-header bg-success bg-lighten-3">
                              <h2 class="card-title">
                                <div class="row">
                                  <div class="col-md-3 col-lg-4">
                                    {{ $t("SigninActivity.name") }}
                                  </div>
                                  <div class="col-md-3 col-lg-4">
                                    {{ $t("SigninActivity.rateprice") }}
                                  </div>
                                  <div class="col-md-3 col-lg-4">
                                    {{ $t("SigninActivity.price") }}
                                  </div>
                                </div>
                              </h2>
                            </div>
                            <div class="card-body border-success border-lighten-2">
                              <div
                                class="row"
                                v-for="(item, index) in package"
                                :key="item.activity"
                              >
                                <div
                                  class="col-md-3 col-xl-3 col-3 d-flex align-end justify-end"
                                >
                                  <div class="form-group pt-2 mb-0">
                                    <div
                                      class="dropdown select"
                                      @click="getfocus(`activity_name_${index}`)"
                                      @mouseleave="mouseOver(`activity_name_${index}`)"
                                    >
                                      <a :id="`activity_name_${index}`">
                                        <v-select
                                          v-model="item.activity"
                                          :items="checkActivityItems(item, index)"
                                          item-text="name"
                                          item-value="_id"
                                          label
                                          :placeholder="$t('SignupStep.select_one')"
                                          :no-data-text="$t('SignupStep.nodata')"
                                          color="#1e9ff2"
                                          solo
                                          flat
                                          dense
                                          single-line
                                          hide-details
                                          @change="
                                            selectActivity(
                                              item,
                                              index,
                                              item.activity,
                                              'activity'
                                            )
                                          "
                                        >
                                          <template v-slot:item="data">
                                            <v-list-item-content
                                              @mouseenter="
                                                getfocus(`activity_name_${index}`)
                                              "
                                              @mouseleave="
                                                mouseOver(`activity_name_${index}`)
                                              "
                                            >
                                              <v-list-item-title
                                                v-html="data.item.name"
                                                class="title-signup-sj"
                                              ></v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-select>
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="col-md-2 col-xl-2 col-2 d-flex align-end justify-end"
                                >
                                  <div class="form-group pt-2 mb-0" style="width: 100%">
                                    <div
                                      class="dropdown select"
                                      @click="getfocus(`activity_per_${index}`)"
                                      @mouseleave="mouseOver(`activity_per_${index}`)"
                                    >
                                      <a :id="`activity_per_${index}`">
                                        <v-select
                                          v-model="item.unit"
                                          :items="item.itemsActivityUnit"
                                          item-text="name"
                                          item-value="value"
                                          label
                                          :placeholder="$t('SignupStep.select_one')"
                                          :no-data-text="$t('SignupStep.nodata')"
                                          color="#1e9ff2"
                                          solo
                                          flat
                                          dense
                                          single-line
                                          hide-details
                                          @change="
                                            selectActivity(
                                              item,
                                              index,
                                              item.activity,
                                              'unit'
                                            )
                                          "
                                        >
                                          <template v-slot:item="data">
                                            <v-list-item-content
                                              @mouseenter="
                                                getfocus(`activity_per_${index}`)
                                              "
                                              @mouseleave="
                                                mouseOver(`activity_per_${index}`)
                                              "
                                            >
                                              <v-list-item-title
                                                v-html="data.item.name"
                                                class="title-signup-sj"
                                              ></v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-select>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  :style="
                                    item.unit !== 'person' && 'display: none !important;'
                                  "
                                  class="col-md-2 col-xl-2 col-2"
                                ></div>
                                <div
                                  :style="
                                    item.unit !== 'person' && 'display: none !important;'
                                  "
                                  class="col-md-2 col-xl-2 col-2 d-flex align-items-end"
                                >
                                  <div class="form-group mb-0">
                                    <small for>
                                      {{ $t("SignupStep3.adult") }}
                                    </small>
                                    <input
                                      type="number"
                                      min="0"
                                      @keypress="isNumber($event)"
                                      :id="`activity_adultrate_${index}`"
                                      class="form-control"
                                      style="min-height: 3rem"
                                      placeholder
                                      :title="$t('SignupStep3.adult')"
                                      name
                                      v-model="item.adult_rate"
                                      @focus="getfocus(`activity_adultrate_${index}`)"
                                      @change="getfocus(`activity_adultrate_${index}`)"
                                      @mouseleave="
                                        mouseOver(`activity_adultrate_${index}`)
                                      "
                                    />
                                  </div>
                                </div>
                                <div
                                  :style="
                                    item.unit !== 'person' && 'display: none !important;'
                                  "
                                  class="col-md-2 col-xl-2 col-2 d-flex align-items-end"
                                >
                                  <div class="form-group mb-0">
                                    <small for>
                                      {{ $t("SignupStep3.child") }}
                                    </small>
                                    <input
                                      type="number"
                                      min="0"
                                      @keypress="isNumber($event)"
                                      :id="`activity_childrate_${index}`"
                                      class="form-control"
                                      style="min-height: 3rem"
                                      placeholder
                                      :title="$t('SignupStep3.child')"
                                      name
                                      v-model="item.child_rate"
                                      @focus="getfocus(`activity_childrate_${index}`)"
                                      @change="getfocus(`activity_childrate_${index}`)"
                                      @mouseleave="
                                        mouseOver(`activity_childrate_${index}`)
                                      "
                                    />
                                  </div>
                                </div>
                                <div
                                  :style="
                                    item.unit !== 'voucher' && 'display: none !important;'
                                  "
                                  class="col-md-2 col-xl-2 col-2 d-flex align-items-end"
                                >
                                  <div class="form-group mb-0">
                                    <small for>
                                      {{ $t("SignupStep3.baserate") }}
                                    </small>
                                    <input
                                      type="number"
                                      min="0"
                                      @keypress="isNumber($event)"
                                      :id="`activity_baserate_${index}`"
                                      class="form-control"
                                      style="min-height: 3rem"
                                      placeholder
                                      :title="$t('SignupStep3.baserate')"
                                      name
                                      v-model="item.base_rate"
                                      @focus="getfocus(`activity_baserate_${index}`)"
                                      @change="getfocus(`activity_baserate_${index}`)"
                                      @mouseleave="
                                        mouseOver(`activity_baserate_${index}`)
                                      "
                                    />
                                  </div>
                                </div>
                                <div
                                  :style="
                                    item.unit !== 'voucher' && 'display: none !important;'
                                  "
                                  class="col-md-2 col-xl-2 col-2 d-flex align-items-end"
                                >
                                  <div class="form-group mb-0">
                                    <small for>
                                      {{ $t("SignupStep3.extrabed")
                                      }}{{ $t("SignupStep3.adult") }}
                                    </small>
                                    <input
                                      type="number"
                                      min="0"
                                      @keypress="isNumber($event)"
                                      :id="`activity_extrabedadult_${index}`"
                                      class="form-control"
                                      style="min-height: 3rem"
                                      placeholder
                                      title
                                      name
                                      v-model="item.extra_bed_adult"
                                      @focus="getfocus(`activity_extrabedadult_${index}`)"
                                      @change="
                                        getfocus(`activity_extrabedadult_${index}`)
                                      "
                                      @mouseleave="
                                        mouseOver(`activity_extrabedadult_${index}`)
                                      "
                                    />
                                  </div>
                                </div>
                                <div
                                  :style="
                                    item.unit !== 'voucher' && 'display: none !important;'
                                  "
                                  class="col-md-2 col-xl-2 col-2 d-flex align-items-end"
                                >
                                  <div class="form-group mb-0">
                                    <small for>
                                      {{ $t("SignupStep3.extrabed")
                                      }}{{ $t("SignupStep3.child") }}
                                    </small>
                                    <input
                                      type="number"
                                      min="0"
                                      @keypress="isNumber($event)"
                                      :id="`activity_extrabedchild_${index}`"
                                      class="form-control"
                                      style="min-height: 3rem"
                                      placeholder
                                      title
                                      name
                                      v-model="item.extra_bed_child"
                                      @focus="getfocus(`activity_extrabedchild_${index}`)"
                                      @change="
                                        getfocus(`activity_extrabedchild_${index}`)
                                      "
                                      @mouseleave="
                                        mouseOver(`activity_extrabedchild_${index}`)
                                      "
                                    />
                                  </div>
                                </div>
                                <div
                                  class="col-md-1 col-xl-1 col-1 mt-2 text-center d-flex align-end justify-start"
                                >
                                  <div class="form-group mb-0 pt-1">
                                    <div class="d-flex flex-row align-center mb-3">
                                      <v-icon
                                        v-if="package.length > 1"
                                        color="#ff4961"
                                        @click="removeActivityLine(item, index)"
                                        >mdi-close-circle-outline</v-icon
                                      >
                                      <v-icon
                                        v-if="
                                          index == package.length - 1 &&
                                          checkActivityItems(item, index).length > 1
                                        "
                                        color="success"
                                        class="ml-1"
                                        @click="addNewActivityLine()"
                                        >mdi-plus-circle-outline</v-icon
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 7 -->
              <li class="timeline-item">
                <div class="timeline-badge">
                  <span
                    class="bg-red bg-lighten-1 d-flex align-center justify-center"
                    data-toggle="tooltip"
                    data-placement="right"
                    title
                    data-original-title
                  >
                    <b class="text-white">7</b>
                  </span>
                </div>
                <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                  <div class="card-header">
                    <h4 class="card-title">
                      {{ $t("RateAndInventory.add_rateplan_minimum_stay") }}
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="card-body">
                      <div class="form-group">
                        <div class="controls">
                          <v-radio-group v-model="minimum_stay_value">
                            <v-radio
                              :label="$t('RateAndInventory.add_rateplan_package1')"
                              :value="false"
                              color="#1e9ff2"
                            ></v-radio>
                            <div class="d-flex flex-row align-center justify-start">
                              <v-radio
                                :label="$t('RateAndInventory.add_rateplan_privileges2')"
                                :value="true"
                                color="#1e9ff2"
                              ></v-radio>
                              <div class="mx-3">
                                <div
                                  class="dropdown select"
                                  style="width: 7rem"
                                  @click="getfocus('minimumstay')"
                                  @mouseleave="mouseOver('minimumstay')"
                                >
                                  <a id="minimumstay">
                                    <v-select
                                      v-model="minimum_stay"
                                      :items="itemsMinimumStay"
                                      label
                                      placeholder
                                      :no-data-text="$t('SignupStep.nodata')"
                                      color="#1e9ff2"
                                      solo
                                      flat
                                      dense
                                      single-line
                                      hide-details
                                      :disabled="!minimum_stay_value"
                                    >
                                      <template v-slot:item="data">
                                        <v-list-item-content
                                          @mouseenter="getfocus('minimumstay')"
                                          @mouseleave="mouseOver('minimumstay')"
                                        >
                                          <v-list-item-title
                                            v-html="data.item"
                                            class="title-signup-sj"
                                          ></v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-select>
                                  </a>
                                </div>
                              </div>
                              <div class="align-self-center">
                                <span>
                                  {{ $t("RateAndInventory.add_rateplan_minimum_stay1") }}
                                </span>
                              </div>
                            </div>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- 8 -->
              <li class="timeline-item">
                <div class="timeline-badge">
                  <span
                    class="bg-red bg-lighten-1 d-flex align-center justify-center"
                    data-toggle="tooltip"
                    data-placement="right"
                    title
                    data-original-title
                  >
                    <b class="text-white">8</b>
                  </span>
                </div>
                <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                  <div class="card-header">
                    <h4 class="card-title">
                      {{ $t("RateAndInventory.add_rateplan_rate_plan") }}
                    </h4>
                  </div>
                  <div class="card-content">
                    <div class="card-body">
                      <div class="form-group">
                        <div class="controls">
                          <v-radio-group v-model="master_rate_plan_value">
                            <v-radio
                              :label="$t('RateAndInventory.add_rateplan_rate_plan1')"
                              :value="false"
                              color="#1e9ff2"
                            ></v-radio>
                            <div class="d-flex flex-column align-start justify-start">
                              <v-radio
                                :label="$t('RateAndInventory.add_rateplan_rate_plan2')"
                                :value="true"
                                color="#1e9ff2"
                              ></v-radio>
                              <div
                                class="dropdown select"
                                style="width: 17rem"
                                @click="getfocus('rateplan')"
                                @mouseleave="mouseOver('rateplan')"
                              >
                                <a id="rateplan">
                                  <v-select
                                    v-model="master_rate_plan_price"
                                    :items="itemsDefaultRatePlanPriceSelect"
                                    item-text="rateplan_name"
                                    item-value="_id"
                                    label
                                    :placeholder="$t('SignupStep.select_one')"
                                    :no-data-text="$t('SignupStep.nodata')"
                                    color="#1e9ff2"
                                    solo
                                    flat
                                    dense
                                    single-line
                                    hide-details
                                    :disabled="!master_rate_plan_value"
                                    @change="selectRatePlanFunc(master_rate_plan_price)"
                                  >
                                    <template v-slot:item="data">
                                      <v-list-item-content
                                        @mouseenter="getfocus('rateplan')"
                                        @mouseleave="mouseOver('rateplan')"
                                      >
                                        <v-list-item-title
                                          v-html="data.item.rateplan_name"
                                          class="title-signup-sj"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-select>
                                </a>
                              </div>
                            </div>
                          </v-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <div id="changeChoice82" style>
                <!-- 9 -->
                <li
                  class="timeline-item"
                  :style="!master_rate_plan_value && 'display: none;'"
                >
                  <div class="timeline-badge">
                    <span
                      class="bg-red bg-lighten-1 d-flex align-center justify-center"
                      data-toggle="tooltip"
                      data-placement="right"
                      title
                      data-original-title
                    >
                      <b class="text-white">9</b>
                    </span>
                  </div>
                  <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                    <div class="card-header">
                      <h4 class="card-title">
                        {{ $t("RateAndInventory.add_rateplan_rate") }}
                      </h4>
                    </div>
                    <div class="card-content">
                      <div class="card-body">
                        <div class="form-group">
                          <div class="controls">
                            <v-radio-group v-model="rate_value" @change="resetRate()">
                              <div class="d-flex flex-row align-center justify-start">
                                <v-radio
                                  :label="$t('RateAndInventory.add_rateplan_rate1')"
                                  :value="false"
                                  color="#1e9ff2"
                                ></v-radio>
                                <input
                                  type="number"
                                  min="0"
                                  @keypress="isNumber($event)"
                                  id="higherprice"
                                  class="form-control mx-5"
                                  style="min-height: 3rem; width: 5rem"
                                  placeholder
                                  name
                                  v-model="rate.higherprice"
                                  :disabled="rate_value !== false"
                                  @focus="getfocus('higherprice')"
                                  @change="getfocus('higherprice')"
                                  @mouseleave="mouseOver('higherprice')"
                                  @keyup="modifyRateFunc()"
                                />
                                <span>%</span>
                              </div>
                              <div
                                class="d-flex flex-row align-center justify-start mt-3"
                              >
                                <v-radio
                                  :label="$t('RateAndInventory.add_rateplan_rate2')"
                                  :value="true"
                                  color="#1e9ff2"
                                ></v-radio>
                                <input
                                  type="number"
                                  min="0"
                                  @keypress="isNumber($event)"
                                  id="lowerprice"
                                  class="form-control mx-5"
                                  style="min-height: 3rem; width: 5rem"
                                  placeholder
                                  name
                                  v-model="rate.lowerprice"
                                  :disabled="rate_value !== true"
                                  @focus="getfocus('lowerprice')"
                                  @change="getfocus('lowerprice')"
                                  @mouseleave="mouseOver('lowerprice')"
                                  @keyup="modifyRateFunc()"
                                />
                                <span>%</span>
                              </div>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <!-- 9 -->
                <li
                  class="timeline-item"
                  :style="master_rate_plan_value && 'display: none;'"
                >
                  <div class="timeline-badge">
                    <span
                      class="bg-red bg-lighten-1 d-flex align-center justify-center"
                      data-toggle="tooltip"
                      data-placement="right"
                      title
                      data-original-title
                    >
                      <b class="text-white">9</b>
                    </span>
                  </div>
                  <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                    <div class="card-header d-flex flex-column">
                      <h4 class="card-title">
                        {{ $t("RateAndInventory.add_rateplan_per") }}
                      </h4>
                      <span class="danger-text-input" v-if="dup_rateplan_name_value">
                        *{{ $t("RateAndInventory.dup_rateplan_name_old_per") }}:
                        {{
                          dup_both_per
                            ? $t("RateAndInventory.dup_both_per")
                            : per_room
                            ? $t("RateAndInventory.add_rateplan_room")
                            : $t("RateAndInventory.add_rateplan_person")
                        }}{{ sum_dup_rateplan_name && `(${sum_dup_rateplan_name})` }}
                      </span>
                      <span class="warn-text-input" v-if="rateplanID">
                        *{{ $t("RateAndInventory.warn_old_rateplan_per") }}:
                        {{
                          old_per_room
                            ? $t("SignupStep3.pricepernight")
                            : $t("SignupStep3.priceperperson")
                        }}
                      </span>
                    </div>
                    <div class="card-content">
                      <div class="card-body">
                        <div class="form-group">
                          <div class="controls">
                            <v-radio-group
                              v-model="per_room"
                              @change="checkPerRoomGuest()"
                            >
                              <v-radio
                                :label="$t('RateAndInventory.add_rateplan_room')"
                                :value="true"
                                color="#1e9ff2"
                              ></v-radio>
                              <v-radio
                                :label="$t('RateAndInventory.add_rateplan_person')"
                                :value="false"
                                color="#1e9ff2"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <!-- 10 -->
                <li class="timeline-item">
                  <div class="timeline-badge">
                    <span
                      class="bg-red bg-lighten-1 d-flex align-center justify-center"
                      data-toggle="tooltip"
                      data-placement="right"
                      title
                      data-original-title
                    >
                      <b class="text-white">10</b>
                    </span>
                  </div>
                  <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                    <div class="card-header">
                      <h4 class="card-title">
                        {{ $t("RateAndInventory.add_rateplan_rooms") }}
                      </h4>
                    </div>
                    <div class="card-content">
                      <div class="card-body">
                        <div class="form-group">
                          <div class="controls">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                :value="all_rooms"
                                :checked="all_rooms"
                                name="choice10-2"
                                required
                                id="choice100-2"
                                class="custom-control-input"
                                @change="checkAllRooms()"
                              />
                              <label class="custom-control-label" for="choice100-2">
                                {{
                                  $t("RateAndInventory.add_rateplan_rooms1")
                                    .charAt(0)
                                    .toUpperCase() +
                                  $t("RateAndInventory.add_rateplan_rooms1").slice(1)
                                }}
                              </label>
                            </div>
                            <div v-for="item in itemsAllRooms" :key="item._id">
                              <div
                                v-if="!item.check"
                                class="custom-control custom-checkbox"
                              >
                                <input
                                  :id="`${item._id}`"
                                  name="rooms"
                                  type="checkbox"
                                  class="custom-control-input"
                                  :value="`${item._id}`"
                                  :checked="item.check"
                                  @change="checkSomeRooms(item)"
                                />
                                <label
                                  class="custom-control-label"
                                  :for="`${item._id}`"
                                  >{{ item.room_name.value }}</label
                                >
                              </div>
                              <v-row v-else>
                                <v-col
                                  cols="12"
                                  sm="12"
                                  :md="per_room ? '3' : '4'"
                                  :lg="per_room ? '3' : '4'"
                                >
                                  <div class="custom-control custom-checkbox">
                                    <input
                                      :id="`${item._id}`"
                                      name="rooms"
                                      type="checkbox"
                                      class="custom-control-input"
                                      :value="`${item._id}`"
                                      :checked="item.check"
                                      @change="checkSomeRooms(item)"
                                    />
                                    <label
                                      class="custom-control-label"
                                      :for="`${item._id}`"
                                      >{{ item.room_name.value }}</label
                                    >
                                  </div>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="12"
                                  :md="per_room ? '9' : '8'"
                                  :lg="per_room ? '9' : '8'"
                                >
                                  <table class="table table-roomrate">
                                    <thead>
                                      <tr>
                                        <th v-if="per_room" class="col-rate">
                                          {{ $t("SignupStep3.adultrate") }}
                                        </th>
                                        <th v-if="per_room" class="col-adult">
                                          {{ $t("SignupStep3.extrabed") }}
                                          <small> ({{ $t("SignupStep3.adult") }}) </small>
                                        </th>
                                        <th v-if="per_room" class="col-child">
                                          {{ $t("SignupStep3.extrabed") }}
                                          <small> ({{ $t("SignupStep3.child") }}) </small>
                                        </th>
                                        <th v-if="!per_room" class="col-rate">
                                          {{ $t("SignupStep3.adultrate") }}
                                        </th>
                                        <th v-if="!per_room" class="col-rate">
                                          {{ $t("SignupStep3.childrate") }}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <td v-if="per_room">
                                        <input
                                          type="number"
                                          min="0"
                                          @keypress="isNumber($event)"
                                          class="form-control"
                                          :class="
                                            !item.base_rate &&
                                            !master_rate_plan_value &&
                                            'border-pink-focus'
                                          "
                                          placeholder
                                          name
                                          v-model="item.base_rate"
                                          :disabled="master_rate_plan_value"
                                        />
                                        <span
                                          v-if="
                                            !item.base_rate && !master_rate_plan_value
                                          "
                                          class="error-text-input"
                                        >
                                          {{ $t("Alert.pleasefilldata") }}
                                        </span>
                                      </td>
                                      <td v-if="per_room">
                                        <input
                                          type="number"
                                          min="0"
                                          @keypress="isNumber($event)"
                                          class="form-control"
                                          :class="
                                            item.customer.normal < item.customer.max &&
                                            !item.extra_bed_adult &&
                                            !master_rate_plan_value &&
                                            'border-pink-focus'
                                          "
                                          placeholder
                                          name
                                          v-model="item.extra_bed_adult"
                                          :disabled="
                                            master_rate_plan_value ||
                                            (!master_rate_plan_value &&
                                              item.customer.normal >= item.customer.max)
                                          "
                                        />
                                        <span
                                          v-if="
                                            item.customer.normal < item.customer.max &&
                                            !item.extra_bed_adult &&
                                            !master_rate_plan_value
                                          "
                                          class="error-text-input"
                                        >
                                          {{ $t("Alert.pleasefilldata") }}
                                        </span>
                                      </td>
                                      <td v-if="per_room">
                                        <input
                                          type="number"
                                          min="0"
                                          @keypress="isNumber($event)"
                                          class="form-control"
                                          :class="
                                            item.customer.normal < item.customer.max &&
                                            !item.extra_bed_child &&
                                            !master_rate_plan_value &&
                                            'border-pink-focus'
                                          "
                                          placeholder
                                          name
                                          v-model="item.extra_bed_child"
                                          :disabled="
                                            master_rate_plan_value ||
                                            (!master_rate_plan_value &&
                                              item.customer.normal >= item.customer.max)
                                          "
                                        />
                                        <span
                                          v-if="
                                            item.customer.normal < item.customer.max &&
                                            !item.extra_bed_child &&
                                            !master_rate_plan_value
                                          "
                                          class="error-text-input"
                                        >
                                          {{ $t("Alert.pleasefilldata") }}
                                        </span>
                                      </td>
                                      <td v-if="!per_room">
                                        <input
                                          type="number"
                                          min="0"
                                          @keypress="isNumber($event)"
                                          class="form-control"
                                          :class="
                                            !item.adult_rate &&
                                            !master_rate_plan_value &&
                                            'border-pink-focus'
                                          "
                                          placeholder
                                          name
                                          v-model="item.adult_rate"
                                          :disabled="master_rate_plan_value"
                                        />
                                        <span
                                          v-if="
                                            !item.adult_rate && !master_rate_plan_value
                                          "
                                          class="error-text-input"
                                        >
                                          {{ $t("Alert.pleasefilldata") }}
                                        </span>
                                      </td>
                                      <td v-if="!per_room">
                                        <input
                                          type="number"
                                          min="0"
                                          @keypress="isNumber($event)"
                                          class="form-control"
                                          :class="
                                            !item.child_rate &&
                                            !master_rate_plan_value &&
                                            'border-pink-focus'
                                          "
                                          placeholder
                                          name
                                          v-model="item.child_rate"
                                          :disabled="master_rate_plan_value"
                                        />
                                        <span
                                          v-if="
                                            !item.child_rate && !master_rate_plan_value
                                          "
                                          class="error-text-input"
                                        >
                                          {{ $t("Alert.pleasefilldata") }}
                                        </span>
                                      </td>
                                    </tbody>
                                  </table>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <!-- 11 -->
                <li class="timeline-item">
                  <div class="timeline-badge">
                    <span
                      class="bg-red bg-lighten-1 d-flex align-center justify-center"
                      data-toggle="tooltip"
                      data-placement="right"
                      title
                      data-original-title
                    >
                      <b class="text-white">11</b>
                    </span>
                  </div>
                  <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                    <div class="card-header">
                      <h4 class="card-title">
                        {{ $t("RateAndInventory.add_rateplan_age_of_voucher") }}
                      </h4>
                    </div>
                    <div class="card-content">
                      <div class="card-body">
                        <div class="form-group">
                          <div class="controls">
                            <v-radio-group
                              v-model="age_of_voucher_value"
                              @change="checkVoucherFunc"
                            >
                              <div class="d-flex flex-row align-center justify-start">
                                <v-radio
                                  :label="
                                    $t('RateAndInventory.add_rateplan_age_of_voucher1')
                                  "
                                  :value="false"
                                  color="#1e9ff2"
                                ></v-radio>
                                <input
                                  type="number"
                                  min="180"
                                  @keypress="isNumber($event)"
                                  id="agelength"
                                  class="form-control mx-5"
                                  style="min-height: 3rem; width: 5rem"
                                  placeholder
                                  name
                                  v-model="age_of_voucher.length"
                                  :disabled="
                                    age_of_voucher_value == true ||
                                    age_of_voucher_value == null
                                  "
                                  @focus="getfocus('agelength')"
                                  @change="
                                    [getfocus('agelength'), changeAgeLVoucherFunc($event)]
                                  "
                                  @mouseleave="mouseOver('agelength')"
                                />
                                <span>{{ $t("RateAndInventory.days") }}</span>
                              </div>
                              <div
                                class="d-flex flex-row align-center justify-start mt-3"
                              >
                                <v-radio
                                  :label="
                                    $t('RateAndInventory.add_rateplan_age_of_voucher2')
                                  "
                                  :value="true"
                                  color="#1e9ff2"
                                  @change="getfocus('choice112_date')"
                                ></v-radio>
                                <input
                                  type="date"
                                  id="choice112_date"
                                  class="form-control ml-3"
                                  name="choice112_date"
                                  data-placement="top"
                                  data-title="Date"
                                  data-original-title
                                  title
                                  style="min-height: 3rem; width: 15rem"
                                  :disabled="!age_of_voucher_value"
                                  v-model="age_of_voucher.expire_date"
                                  :min="minDateAgeOfVoucherExpireDate"
                                  @keypress="changeAgeDVoucherFunc($event)"
                                />
                              </div>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <!-- 12 -->
                <li class="timeline-item">
                  <div class="timeline-badge">
                    <span
                      class="bg-red bg-lighten-1 d-flex align-center justify-center"
                      data-toggle="tooltip"
                      data-placement="right"
                      title
                      data-original-title
                    >
                      <b class="text-white">12</b>
                    </span>
                  </div>
                  <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                    <div class="card-header">
                      <h4 class="card-title">
                        {{ $t("RateAndInventory.add_rateplan_holiday_booking_fee") }}
                      </h4>
                    </div>
                    <div class="alert alert-warning ml-2 mb-2 w-75" role="alert">
                      <v-icon small color="#963b00">mdi-alert-circle-outline</v-icon>
                      {{ $t("RateAndInventory.add_rateplan_holiday_booking_fee3") }}
                    </div>
                    <div class="card-content">
                      <div class="card-body">
                        <div class="form-group">
                          <div class="controls">
                            <v-radio-group v-model="holiday_booking_fee_value">
                              <v-radio
                                :label="
                                  $t('RateAndInventory.add_rateplan_holiday_booking_fee1')
                                "
                                :value="false"
                                color="#1e9ff2"
                              ></v-radio>
                              <div class="d-flex flex-column align-start justify-start">
                                <v-radio
                                  :label="
                                    $t(
                                      'RateAndInventory.add_rateplan_holiday_booking_fee2'
                                    )
                                  "
                                  :value="true"
                                  color="#1e9ff2"
                                ></v-radio>
                                <div
                                  class="row pt-1 pl-lg-3"
                                  id="choice12more-2"
                                  v-if="holiday_booking_fee_value"
                                >
                                  <div class="col-md-12" style="height: auto;">
                                    <div
                                      class="card box-shadow-0 border-success bg-transparent p-1" style="height: auto;"
                                    >
                                      <div class="card-header bg-transparent">
                                        <h4 class="card-title">
                                          {{
                                            $t(
                                              "RateAndInventory.add_rateplan_holiday_booking_fee4"
                                            )
                                          }}
                                        </h4>
                                      </div>
                                      <div class="card-content collapse show">
                                        <div class="card-body p-0">
                                          <form class="form-inline">
                                            <div class="form-group mx-sm-1 mb-2">
                                              <label for>
                                                {{
                                                  $t(
                                                    "RateAndInventory.add_rateplan_holiday_booking_fee5"
                                                  )
                                                }}
                                              </label>
                                            </div>
                                            <div class="form-group mx-sm-1 mb-2">
                                              <fieldset>
                                                <div class="input-group">
                                                  <input
                                                    type="number"
                                                    min="0"
                                                    @keypress="isNumber($event)"
                                                    id="holiday_weekend"
                                                    class="form-control"
                                                    style="min-height: 3rem"
                                                    placeholder="200"
                                                    name
                                                    v-model="holiday_booking_fee.weekend"
                                                    @focus="getfocus('holiday_weekend')"
                                                    @change="getfocus('holiday_weekend')"
                                                    @mouseleave="
                                                      mouseOver('holiday_weekend')
                                                    "
                                                  />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text" id
                                                      >฿</span
                                                    >
                                                  </div>
                                                </div>
                                              </fieldset>
                                            </div>
                                            <div class="form-group mx-sm-1 mb-2">
                                              <label for>
                                                {{
                                                  $t(
                                                    "RateAndInventory.add_rateplan_holiday_booking_fee6"
                                                  )
                                                }}
                                              </label>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12" style="height: auto;">
                                    <div
                                      class="card box-shadow-0 border-success bg-transparent p-1" style="height: auto;"
                                    >
                                      <div class="card-header bg-transparent">
                                        <h4 class="card-title">
                                          {{
                                            $t(
                                              "RateAndInventory.add_rateplan_holiday_booking_fee7"
                                            )
                                          }}
                                        </h4>
                                      </div>
                                      <div class="card-content collapse show">
                                        <div class="card-body p-0">
                                          <form class="form-inline">
                                            <div class="form-group mx-sm-1 mb-2">
                                              <label for>
                                                {{
                                                  $t(
                                                    "RateAndInventory.add_rateplan_holiday_booking_fee5"
                                                  )
                                                }}
                                              </label>
                                            </div>
                                            <div class="form-group mx-sm-1 mb-2">
                                              <fieldset>
                                                <div class="input-group">
                                                  <input
                                                    type="number"
                                                    min="0"
                                                    @keypress="isNumber($event)"
                                                    id="holiday_holiday"
                                                    class="form-control"
                                                    style="min-height: 3rem"
                                                    placeholder="200"
                                                    name
                                                    v-model="holiday_booking_fee.holiday"
                                                    @focus="getfocus('holiday_holiday')"
                                                    @change="getfocus('holiday_holiday')"
                                                    @mouseleave="
                                                      mouseOver('holiday_holiday')
                                                    "
                                                  />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text" id
                                                      >฿</span
                                                    >
                                                  </div>
                                                </div>
                                              </fieldset>
                                            </div>
                                            <div class="form-group mx-sm-1 mb-2">
                                              <label for>
                                                {{
                                                  $t(
                                                    "RateAndInventory.add_rateplan_holiday_booking_fee6"
                                                  )
                                                }}
                                              </label>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12" style="height: auto;">
                                    <div
                                      class="card box-shadow-0 border-success bg-transparent p-1" style="height: auto;"
                                    >
                                      <div class="card-header bg-transparent">
                                        <h4 class="card-title">
                                          {{
                                            $t(
                                              "RateAndInventory.add_rateplan_holiday_booking_fee8"
                                            )
                                          }}
                                        </h4>
                                      </div>
                                      <div class="card-content collapse show">
                                        <div class="card-body p-0">
                                          <form class="form-inline">
                                            <div class="form-group mx-sm-1 mb-2">
                                              <label for>
                                                {{
                                                  $t(
                                                    "RateAndInventory.add_rateplan_holiday_booking_fee5"
                                                  )
                                                }}
                                              </label>
                                            </div>
                                            <div class="form-group mx-sm-1 mb-2">
                                              <fieldset>
                                                <div class="input-group">
                                                  <input
                                                    type="number"
                                                    min="0"
                                                    @keypress="isNumber($event)"
                                                    id="holiday_special_holiday"
                                                    class="form-control"
                                                    style="min-height: 3rem"
                                                    placeholder="200"
                                                    name
                                                    v-model="
                                                      holiday_booking_fee.special_holiday
                                                    "
                                                    @focus="
                                                      getfocus('holiday_special_holiday')
                                                    "
                                                    @change="
                                                      getfocus('holiday_special_holiday')
                                                    "
                                                    @mouseleave="
                                                      mouseOver('holiday_special_holiday')
                                                    "
                                                  />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text" id
                                                      >฿</span
                                                    >
                                                  </div>
                                                </div>
                                              </fieldset>
                                            </div>
                                            <div class="form-group mx-sm-1 mb-2">
                                              <label for>
                                                {{
                                                  $t(
                                                    "RateAndInventory.add_rateplan_holiday_booking_fee6"
                                                  )
                                                }}
                                              </label>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <!-- 13 -->
                <li class="timeline-item">
                  <div class="timeline-badge">
                    <span
                      class="bg-red bg-lighten-1 d-flex align-center justify-center"
                      data-toggle="tooltip"
                      data-placement="right"
                      title
                      data-original-title
                    >
                      <b class="text-white">13</b>
                    </span>
                  </div>
                  <div class="timeline-card card border-grey border-lighten-2" style="height: auto;">
                    <div class="card-header">
                      <h4 class="card-title">
                        {{ $t("RateAndInventory.add_rateplan_season_add_price") }}
                      </h4>
                    </div>
                    <div class="alert alert-warning ml-2 mb-2 w-75" role="alert">
                      <v-icon small color="#963b00">mdi-alert-circle-outline</v-icon>
                      {{ $t("RateAndInventory.add_rateplan_season_add_price7") }}
                    </div>

                    <div class="card-content">
                      <div class="card-body">
                        <div class="form-group">
                          <div class="controls">
                            <v-radio-group v-model="season_add_price_value">
                              <v-radio
                                :label="
                                  $t('RateAndInventory.add_rateplan_season_add_price1')
                                "
                                :value="false"
                                color="#1e9ff2"
                              ></v-radio>
                              <div class="d-flex flex-column align-start justify-start">
                                <v-radio
                                  :label="
                                    $t('RateAndInventory.add_rateplan_season_add_price2')
                                  "
                                  :value="true"
                                  color="#1e9ff2"
                                ></v-radio>
                                <div id="choice13more-2" v-if="season_add_price_value">
                                  <div v-if="season_add_price.length == 0"></div>
                                  <template v-for="item in season_add_price" v-else>
                                    <div
                                      :key="item._id"
                                      class="card box-shadow-0 border-success bg-transparent p-1 ml-xl-3 col-xl-8 col-xxl-7 mb-5"
                                    >
                                      <div class="card-content collapse show">
                                        <div class="card-body p-1">
                                          <form class="form-inline row">
                                            <div class="col-md-7 col-lg-auto form-group">
                                              <label for>
                                                {{ item.seasonal_name }}
                                                :
                                                {{
                                                  moment(item.date_start).format(
                                                    "YYYY-MM-DD"
                                                  )
                                                }}
                                                {{
                                                  $t(
                                                    "RateAndInventory.add_rateplan_season_add_price6"
                                                  )
                                                }}
                                                {{
                                                  moment(item.date_end).format(
                                                    "YYYY-MM-DD"
                                                  )
                                                }}
                                              </label>
                                            </div>
                                            <div class="col-md-5 col-lg-auto form-group">
                                              <fieldset>
                                                <div class="input-group">
                                                  <input
                                                    type="number"
                                                    min="0"
                                                    @keypress="isNumber($event)"
                                                    :id="item._id"
                                                    class="form-control"
                                                    style="min-height: 3rem"
                                                    placeholder="200"
                                                    name
                                                    v-model="item.increase_price"
                                                    @focus="getfocus(`${item._id}`)"
                                                    @change="getfocus(`${item._id}`)"
                                                    @mouseleave="mouseOver(`${item._id}`)"
                                                  />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text" id
                                                      >฿</span
                                                    >
                                                  </div>
                                                </div>
                                              </fieldset>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </section>
        </div>
      </div>
    </div>
    <div v-if="!loadingOldData" class="col-md-12 py-3 margin-vertical-3">
      <div class="row">
        <div class="col-md-12">
          <div class="btn-data-table btn-warning" @click="gobackFunc()">
            {{ $t("RateAndInventory.btn_back") }}
          </div>
          <div class="btn-data-table btn-info ml-1" @click="createRatePlanPrice()">
            {{ $t("RateAndInventory.btn_save") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninRatePlanFixScreen",
  metaInfo: {
    title: "Fix Rate Plan",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    var changeValue = this.checkChangeData();

    if (changeValue) {
      const answer = window.confirm(this.$t("Alert.needtogootherroute"));
      if (answer) {
        next();
      } else {
        this.initAll();
        next(false);
      }
    } else next();
  },
  data: () => ({
    moment: moment,
    userToken: "",
    propertyID: "",
    value_element: "",
    propertyData: null,
    itemsAddRatePlan: [],
    master_rate_plan_value: null,
    rate_plan: "",
    master_rate_plan_price: "",
    name: "",
    per_room: true,
    per_guest: false,
    all_rooms: null,
    rooms: [
      {
        room: "610b9de6ea6d40255921277e",
        base_rate: 1400,
        extra_bed_adult: 500,
        extra_bed_child: 300,
      },
    ],
    advance_booking: 0,
    change_date: undefined,
    privileges: null,
    food_service: null,
    food_service_select: undefined,
    all_food_service: null,
    package_value: null,
    package: [],
    minimum_stay_value: null,
    minimum_stay: null,
    age_of_voucher_value: null,
    age_of_voucher: {
      length: null,
      expire_date: "",
    },
    rate_value: null,
    rate: {
      enum: null,
      lowerprice: null,
      higherprice: null,
      type: "increase",
      unit: "percent",
    },
    holiday_booking_fee_value: null,
    holiday_booking_fee: {
      weekend: 0,
      holiday: 0,
      special_holiday: 0,
    },
    season_add_price_value: null,
    season_add_price: {
      low_season: 0,
      high_season: 0,
      peak_season: 0,
    },
    itemsAdvanceBooking: [],
    itemsChangeDate: [],
    itemsFoodService: [],
    itemsDefaultActivity: [],
    itemsActivityRatePlan: [],
    itemsActivityUnit: [],
    itemsMinimumStay: [2, 3, 4, 5],
    itemsAllRatePlanPrice: [],
    itemsAllRatePlanName: [],
    itemsDefaultAllRooms: [],
    itemsAllRooms: [],
    rateplanID: "",
    rateplanStatus: "active",
    oldRatePlanData: {},
    saveSuccess: false,
    isSignupValue: null,
    old_rateplan_name: "",
    old_master_rateplan_price: "",
    old_per_room: true,
    sum_dup_rateplan_name: 0,
    items_dup_rateplan_name: [],
    dup_rateplan_name_value: false,
    dup_both_per: false,
    dup_per_room: true,
    itemsDefaultRatePlanPrice: [],
    itemsDefaultRatePlanPriceSelect: [],
    minDateAgeOfVoucherExpireDate: "",
    loadingOldData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.rateplanID = self.$route.params.rateplanId || "";
    self.initAll();
  },
  watch: {
    name() {
      const self = this;
      var sum = 0;
      var tmp_per_room = false;
      var tmp_per_guest = false;
      var tmpDup = [];
      self?.itemsAllRatePlanName?.map((p) => {
        if (p.name == self.name && self.rateplanID && p._id !== self.rateplanID) {
          sum += 1;
          tmpDup.push(p);
          if (p.per_room == true) tmp_per_room = true;
          if (p.per_room == false) tmp_per_guest = true;
        } else if (p.name == self.name && !self.rateplanID) {
          sum += 1;
          tmpDup.push(p);
          if (p.per_room == true) tmp_per_room = true;
          if (p.per_room == false) tmp_per_guest = true;
        }
      });
      self.sum_dup_rateplan_name = sum;
      self.items_dup_rateplan_name = tmpDup;
      self.dup_both_per = tmp_per_room && tmp_per_guest ? true : false;

      if (self.rateplanID) {
        const checkName = self.itemsAllRatePlanName?.find(
          (p) => p.name == self.name && p._id !== self.rateplanID
        );
        if (checkName) {
          self.dup_rateplan_name_value = true;
          self.dup_per_room = checkName?.per_room;
        } else {
          self.dup_rateplan_name_value = false;
          self.dup_per_room = checkName?.per_room;
        }
      } else {
        const checkName = self.itemsAllRatePlanName?.find((p) => p.name == self.name);
        if (checkName) {
          self.dup_rateplan_name_value = true;
          self.dup_per_room = checkName?.per_room;
        } else {
          self.dup_rateplan_name_value = false;
          self.dup_per_room = checkName?.per_room;
        }
      }
    },
    holiday_booking_fee_value() {
      const self = this;
      var item = {
        link: "weekendandholiday",
        name: "SigninWeekendAndHolidayScreen",
      };
      if (self?.holiday_booking_fee_value) {
        // console.log(self?.propertyData?.weekend_and_holiday_rate)
        if (
          self?.propertyData?.weekend_and_holiday_rate?.weekend?.enable &&
          self?.propertyData?.weekend_and_holiday_rate?.holiday_and_special?.enable
        ) {
          if (
            (self?.propertyData?.weekend_and_holiday_rate?.weekend?.value?.monday ||
              self?.propertyData?.weekend_and_holiday_rate?.weekend?.value?.tuesday ||
              self?.propertyData?.weekend_and_holiday_rate?.weekend?.value?.wednesday ||
              self?.propertyData?.weekend_and_holiday_rate?.weekend?.value?.thursday ||
              self?.propertyData?.weekend_and_holiday_rate?.weekend?.value?.friday ||
              self?.propertyData?.weekend_and_holiday_rate?.weekend?.value?.saturday ||
              self?.propertyData?.weekend_and_holiday_rate?.weekend?.value?.sunday ||
              self?.propertyData?.weekend_and_holiday_rate?.weekend?.value?.length) 
              // && self?.propertyData?.weekend_and_holiday_rate?.holiday_and_special?.value?.length
          ) {
          } else {
            swal(self.$t("RateAndInventory.nosettingdata"), {
              dangerMode: true,
              buttons: {
                cancel: self.$t("SigninWeekend.dialog_close"),
                confirm: {
                  text: self.$t("RateAndInventory.gosetting"),
                  value: "confirm",
                },
              },
            }).then((value) => {
              if (value === "confirm") {
                EventBus.$emit("changePathname", item);
              } else {
                self.holiday_booking_fee_value = false;
                // this.initAll();
              }
            });
          }
        } else {
          swal(self.$t("RateAndInventory.nosettingdata"), {
            dangerMode: true,
            buttons: {
              cancel: self.$t("SigninWeekend.dialog_close"),
              confirm: {
                text: self.$t("RateAndInventory.gosetting"),
                value: "confirm",
              },
            },
          }).then((value) => {
            if (value === "confirm") {
              EventBus.$emit("changePathname", item);
            } else {
              self.holiday_booking_fee_value = false;
              // this.initAll();
            }
          });
        }
      }
    },
    season_add_price_value() {
      const self = this;
      var item = {
        link: "seasonal",
        name: "SigninSeasonalScreen",
      };
      // console.log("seasonal: ", self?.propertyData?.seasonal);
      if (self.season_add_price_value) {
        if (self?.propertyData?.seasonal?.length) {
        } else {
          swal(self.$t("RateAndInventory.nosettingdata"), {
            dangerMode: true,
            buttons: {
              cancel: self.$t("SigninWeekend.dialog_close"),
              confirm: {
                text: self.$t("RateAndInventory.gosetting"),
                value: "confirm",
              },
            },
          }).then((value) => {
            if (value === "confirm") {
              EventBus.$emit("changePathname", item);
            } else {
              self.season_add_price_value = false;
              // this.initAll();
            }
          });
        }
      }
    },
    master_rate_plan_value() {
      const self = this;
      if (!self?.master_rate_plan_value) {
        // console.log(self.itemsAllRooms);
        self.itemsAllRooms = self.itemsDefaultAllRooms;
        if (self.old_master_rateplan_price) {
          self.selectRatePlanFunc();
          self.checkAllRooms();
          // self.selectRatePlanFunc();
          if (!self.old_master_rateplan_price)
            self.itemsAllRooms?.map((el) => {
              // if (
              //   el?.check &&
              //   (!self.rateplanID || self?.old_master_rateplan_price)
              // ) {
              el.adult_rate = null;
              el.child_rate = null;
              el.base_rate = null;
              el.extra_bed_adult = null;
              el.extra_bed_child = null;
              // }
            });
        }
      } else if (self?.master_rate_plan_value) {
        self.selectRatePlanFunc(self.master_rate_plan_price);
      }
    },
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-blue-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-blue-focus");
        element.classList.add("border-blue-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-blue-focus");
        element.classList.remove("border-blue-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeAgeLVoucherFunc(evt) {
      const self = this;
      if (self.age_of_voucher.length < 180) {
        evt.preventDefault();
        self.age_of_voucher.length = 180;
      } else {
        return true;
      }
    },
    changeAgeDVoucherFunc(evt) {
      const self = this;
      var minDate = moment().add(6, "month").format("YYYY-MM-DD");
      self.minDateAgeOfVoucherExpireDate = minDate;
      self.age_of_voucher.expire_date = minDate;

      return evt.preventDefault();
    },
    initAll() {
      const self = this;
      self.loadingOldData = true;
      self.itemsAddRatePlan = [
        {
          _id: 0,
          topic: self.$t("RateAndInventory.custom_title"),
          title: self.$t("RateAndInventory.custom_label"),
          description: "",
          type: "custom",
          linkName: "SigninRatePlanFixScreen",
        },
        {
          _id: 1,
          topic: self.$t("RateAndInventory.full_title"),
          title: self.$t("RateAndInventory.full_label"),
          description: "",
          type: "full",
          linkName: "SigninRatePlanFullScreen",
        },
      ];

      // self.name = "Room Only";

      self.itemsAdvanceBooking = [
        {
          value: 1,
          name: `${self.$t("RateAndInventory.add_rateplan_advance3")} 1 ${self.$t(
            "RateAndInventory.days"
          )}`,
        },
        {
          value: 3,
          name: `${self.$t("RateAndInventory.add_rateplan_advance3")} 3 ${self.$t(
            "RateAndInventory.days"
          )}`,
        },
        {
          value: 5,
          name: `${self.$t("RateAndInventory.add_rateplan_advance3")} 5 ${self.$t(
            "RateAndInventory.days"
          )}`,
        },
        {
          value: 7,
          name: `${self.$t("RateAndInventory.add_rateplan_advance3")} 7 ${self.$t(
            "RateAndInventory.days"
          )}`,
        },
        {
          value: 15,
          name: `${self.$t("RateAndInventory.add_rateplan_advance3")} 15 ${self.$t(
            "RateAndInventory.days"
          )}`,
        },
        {
          value: 30,
          name: `${self.$t("RateAndInventory.add_rateplan_advance3")} 30 ${self.$t(
            "RateAndInventory.days"
          )}`,
        },
      ];

      self.advance_booking = null;

      self.itemsFoodService = [
        {
          value: "breakfast",
          name: self.$t("RateAndInventory.add_rateplan_food_service3"),
          check: false,
        },
        {
          value: "breakfast_dinner",
          name: self.$t("RateAndInventory.add_rateplan_food_service4"),
          check: false,
        },
        {
          value: "breakfast_lunch",
          name: self.$t("RateAndInventory.add_rateplan_food_service5"),
          check: false,
        },
        {
          value: "breakfast_lunch_dinner",
          name: self.$t("RateAndInventory.add_rateplan_food_service6"),
          check: false,
        },
      ];

      self.initPropertyData();

      self.itemsActivityUnit = [
        {
          value: "person",
          name: self.$t("RateAndInventory.add_rateplan_package3"),
        },
        {
          value: "voucher",
          name: self.$t("RateAndInventory.add_rateplan_package4"),
        },
      ];
      setTimeout(() => {
        self.loadingOldData = false;
      }, 1500);
    },
    gobackFunc() {
      const self = this;
      self.loading = true;
      self.gotoLink("SigninRatePlanScreen", null);
    },
    gotoLink(linkName, rateplanId) {
      const link = {
        name: linkName,
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
          rateplanId,
        },
      };
      EventBus.$emit("changePathname", link);
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var tempActivity = [];
      var tmpAllRatePlanNameAndPer = [];
      var tempAllRooms = [];
      var tempSeason = [];
      var tempChangeDate = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
          tempActivity = temp?.activities || [];
          temp?.rate_plan_price?.map((el) => {
            if (
              (el?.name || el?.master_rate_plan_price?.value || el?.rate_plan?.value) &&
              el?.rooms?.length
            ) {
              tmpAllRatePlanNameAndPer.push({
                _id: el?._id,
                name:
                  el?.name || el?.master_rate_plan_price?.value || el?.rate_plan?.value,
                per_room: el?.per_room !== null ? el.per_room : false,
                is_signup: el?.is_signup,
              });
            }
          });
          temp?.rooms?.map((el) => {
            tempAllRooms.push({
              ...el,
              check: false,
              base_rate: null,
              extra_bed_adult: null,
              extra_bed_child: null,
              adult_rate: null,
              child_rate: null,
            });
          });
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        // console.log("property data: ", temp);
        self.itemsAllRatePlanName = tmpAllRatePlanNameAndPer;
        self.itemsDefaultAllRooms = tempAllRooms;
        self.itemsAllRooms = tempAllRooms;
        // console.log("rooms: ", tempAllRooms);
        if (temp?.seasonal?.length) {
          tempSeason = [];
          temp?.seasonal?.map((el) => {
            // console.log(el)
            // console.log("-----", el.seasonal_name);
            el.date_slot.map((d) => {
              var dateValue = d.end;
              d.start_format = moment(d.start).locale("th").format("DD/MM/YYYY");
              d.end_format = moment(d.end).locale("th").format("DD/MM/YYYY");
              var currentDate = new Date(new Date().setUTCHours(0, 0, 0, 0));
              var endDate = new Date(new Date(dateValue).setUTCHours(0, 0, 0, 0));
              if (endDate < currentDate) {
                d.date_past = true;
              } else {
                d.date_past = false;
              }
              // console.log(d.date_past, d);
              if (d.date_past == false) {
                tempSeason.push({
                  ...el,
                  date_start: d.start,
                  date_end: d.end,
                  seasonal: el._id,
                  increase_price: 0,
                });
              }
            });
          });
        }
        self.season_add_price = tempSeason || [];
        temp?.policy_change_date?.map((el) => {
          // console.log("itemsChangeDate: ", el);
          tempChangeDate.push({
            value: el._id,
            name: `${self.$t("SigninPolicy.title_policy_changedate21")} ${
              el.free_before
            } ${self.$t("SigninPolicy.title_policy_changedate3")}`,
          });
        });
        self.itemsChangeDate = tempChangeDate || [];
        self.initActivity(tempActivity);
        self.initDefaultRatePlanFunc();
      }
    },
    async initDefaultRatePlanFunc() {
      const self = this;
      var temp = null;
      var tempPackage = [];
      var tmpAllRatePlan = [];
      var tempRatePlanPrice = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?property_id=${
              self?.propertyID || self?.$route?.params?.propertyId
            }`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            tmpAllRatePlan = res?.data?.result || [];
            const tempdata = res?.data?.result?.find((p) => p._id == self.rateplanID);
            temp = tempdata || null;

            res?.data?.result?.map((el) => {
              // console.log("el: ", el);
              if (
                (el?.name || el?.master_rate_plan_price?.value || el?.rate_plan?.value) &&
                el?._id !== self.rateplanID &&
                el?.rooms?.length &&
                !el?.master_rate_plan_price &&
                el?.status == "active"
              ) {
                tempRatePlanPrice.push({
                  ...el,
                  rateplan_id: el?._id,
                  rateplan_name:
                    el?.name || el?.master_rate_plan_price?.value || el?.rate_plan?.value,
                });
              }
            });
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsDefaultRatePlanPrice = tmpAllRatePlan;
        // console.log(self.itemsDefaultRatePlanPrice)
        self.itemsDefaultRatePlanPriceSelect = tempRatePlanPrice;

        if (self.rateplanID) self.mappingDataFunc(temp);
        else {
          // console.log(self.propertyData);
          self.age_of_voucher_value = false;
          self.checkVoucherFunc();
          self.isSignupValue = false;
          self.advance_booking = self.propertyData?.book_in_advance || 0;
          self.change_date = false;

          self.privileges = false;
          self.food_service = false;
          self.package_value = false;
          self.minimum_stay_value = false;
          self.master_rate_plan_value = false;
          self.age_of_voucher_value = false;
          self.holiday_booking_fee_value =
            self.propertyData?.weekend_and_holiday_rate?.holiday_and_special?.enable ||
            false;
          // self.season_add_price_value =
          //   self.propertyData?.weekend_and_holiday_rate?.holiday_and_special?.enable ||
          //   false;
          self.season_add_price_value = false;
        }
      }
    },
    async mappingDataFunc(temp) {
      const self = this;
      self.loadingOldData = true;
      var tempPackage = [];
      var tmpAllRatePlan = [];

      // console.log("old data: ", temp, "name: ", temp?.name);
      self.name =
        temp?.name || temp?.master_rate_plan_price?.value || temp?.rate_plan?.value || "";
      self.old_rateplan_name =
        temp?.name || temp?.master_rate_plan_price?.value || temp?.rate_plan?.value || "";
      self.old_master_rateplan_price =
        temp?.master_rate_plan_price?._id || temp?.master_rate_plan_price || null;
      self.advance_booking =
        temp?.is_signup && !temp?.advance_booking
          ? self.propertyData?.book_in_advance
          : temp?.advance_booking || null;
      self.change_date = temp?.policy_change_date?._id || false;
      self.food_service = temp?.food_service
        ? true
        : temp?.is_signup && (temp?.food_service == true || temp?.food_service == false)
        ? true
        : temp?.food_service || false;
      self.food_service_select =
        temp?.is_signup && (temp?.food_service == true || temp?.food_service == false)
          ? "breakfast"
          : temp?.food_service || undefined;
      self.privileges = temp?.privileges;
      self.rateplanStatus = temp?.status || "active";
      if (temp?.package?.length) {
        temp?.package?.map((el) => {
          // console.log("activity: ", el);
          tempPackage.push({
            _id: el?._id,
            name: el?.name || "",
            activity: el?.activity?._id,
            unit: el?.unit,
            perprice: el?.unit == "person" ? "guest" : "room",
            per_room: el?.unit == "person" ? false : true,
            per_guest: el?.unit == "person" ? true : false,
            base_rate: el?.base_rate || 0,
            extra_bed_adult: el?.extra_bed_adult || 0,
            extra_bed_child: el?.extra_bed_child || 0,
            adult_rate: el?.adult_rate || 0,
            child_rate: el?.child_rate || 0,
            hoverdel: false,
            canremove: false,
            itemsActivityUnit: self.itemsActivityUnit,
            items_activity: self.itemsActivityRatePlan,
          });
        });
        tempPackage.sort((a, b) => a?.name?.localeCompare(b?.name) || a?.name - b?.name);
        self.package = tempPackage;
      } else {
        self.package = [];
        self.addNewActivityLine();
      }
      self.package_value = tempPackage?.length ? true : false;
      self.oldRatePlanData = temp;
      self.minimum_stay_value = temp?.minimum_stay > 1 ? true : false;
      self.minimum_stay = temp?.is_signup && !temp?.minimum_stay ? 3 : temp?.minimum_stay;
      var mapOldRatePlan = null;
      self?.itemsDefaultRatePlanPriceSelect?.map((elem) => {
        if (
          elem.rateplan_id == temp.master_rate_plan_price?._id ||
          elem.rateplan_id == temp.master_rate_plan_price
        ) {
          mapOldRatePlan = elem;
        }
      });
      self.itemsAllRatePlanName?.map((el) => {
        const checkSignup = tmpAllRatePlan?.find((p) => p?._id == el?._id);
        el.is_signup = checkSignup?.is_signup || false;
      });
      // console.log("map rate plan: ", mapOldRatePlan, temp, self?.itemsDefaultRatePlanPriceSelect);
      self.master_rate_plan_value = mapOldRatePlan ? true : false;
      self.rate_plan = mapOldRatePlan?.rateplan_id;
      self.master_rate_plan_price =
        temp?.master_rate_plan_price?._id || temp?.master_rate_plan_price || null;
      self.isSignupValue =
        temp?.is_signup || (temp?.master_rate_plan_price && !mapOldRatePlan)
          ? true
          : false;
      if (self.master_rate_plan_value) {
        self.rate_value = temp?.rate?.type == "increase" ? false : true;
        self.rate.higherprice = temp?.rate?.type == "increase" ? temp?.rate?.enum : null;
        self.rate.lowerprice = temp?.rate?.type == "decrease" ? temp?.rate?.enum : null;
      }
      self.per_room = temp?.per_room || false;
      self.per_guest = !self.per_room;
      self.old_per_room = temp?.per_room || false;

      const oldRatePlanData = self?.itemsDefaultRatePlanPriceSelect?.find(
        (p) =>
          p?.rateplan_id == self.master_rate_plan_price ||
          p?.rateplan_id == self.master_rate_plan_price?._id
      );
      if (self.master_rate_plan_price && oldRatePlanData?.rateplan_id)
        self.selectRatePlanFunc(self.master_rate_plan_price);
      else {
        if (temp?.rooms?.length == self?.itemsAllRooms?.length) {
          self.all_rooms = null;
          self.itemsAllRooms?.map((el) => {
            const tempRoom = temp?.rooms?.find(
              (p) => p?._id == el?._id || p?.room?._id == el?._id
            );
            // console.log(tempRoom);
            if (tempRoom?._id) {
              el.check = true;
              el.base_rate = tempRoom?.base_rate || null;
              el.extra_bed_adult = tempRoom?.extra_bed_adult || null;
              el.extra_bed_child = tempRoom?.extra_bed_child || null;
              el.adult_rate = tempRoom?.adult_rate || null;
              el.child_rate = tempRoom?.child_rate || null;
            } else el.check = false;
          });
          self.checkAllRooms();
        } else {
          self.itemsAllRooms?.map((el) => {
            const tempRoom = temp?.rooms?.find(
              (p) => p?._id == el?._id || p?.room?._id == el?._id
            );
            if (tempRoom?._id) {
              el.check = true;
              el.base_rate = tempRoom?.base_rate || null;
              el.extra_bed_adult = tempRoom?.extra_bed_adult || null;
              el.extra_bed_child = tempRoom?.extra_bed_child || null;
              el.adult_rate = tempRoom?.adult_rate || null;
              el.child_rate = tempRoom?.child_rate || null;
            } else el.check = false;
          });
        }
      }
      self.age_of_voucher.length =
        temp?.is_signup && !temp?.age_of_voucher?.length
          ? 365
          : temp?.age_of_voucher?.length || null;
      self.age_of_voucher.expire_date = temp?.age_of_voucher?.expire_date
        ? moment(temp?.age_of_voucher?.expire_date).format("YYYY-MM-DD")
        : null;
      self.minDateAgeOfVoucherExpireDate =
        moment(temp?.age_of_voucher?.expire_date).add(6, "month").format("YYYY-MM-DD") ||
        moment().add(6, "month").format("YYYY-MM-DD");
      self.age_of_voucher_value = self.age_of_voucher.length
        ? false
        : self.age_of_voucher.expire_date
        ? true
        : null;
      self.holiday_booking_fee_value =
        temp?.holiday_booking_fee?.holiday ||
        temp?.holiday_booking_fee?.special_holiday ||
        temp?.holiday_booking_fee?.weekend
          ? true
          : false;
      self.holiday_booking_fee.weekend = temp?.holiday_booking_fee?.weekend;
      self.holiday_booking_fee.holiday = temp?.holiday_booking_fee?.holiday;
      self.holiday_booking_fee.special_holiday =
        temp?.holiday_booking_fee?.special_holiday;
      self.season_add_price_value = false;
      self.season_add_price?.map((el) => {
        const tempSeason = temp?.seasonal_increase_price?.find(
          (p) => p?.seasonal?._id == el._id
        );
        if (tempSeason?._id) {
          el.increase_price = tempSeason?.increase_price || 0;
          if (el.increase_price) self.season_add_price_value = true;
        }
      });
      setTimeout(() => {
        self.loadingOldData = false;
      }, 1500);
    },
    selectRatePlanFunc(item) {
      const self = this;
      var tmpRooms = [];
      self.itemsAllRooms = self?.itemsDefaultAllRooms;
      if (self?.master_rate_plan_value && self.master_rate_plan_price && item) {
        const temp = self?.itemsDefaultRatePlanPriceSelect?.find(
          (p) => p?.rateplan_id == item || p?.rateplan_id == item?._id
        );
        // console.log(item, temp);
        self.per_room = temp?.per_room !== null ? temp?.per_room : false;
        self?.itemsAllRooms?.map((el) => {
          const findSameRoom = temp?.rooms?.find((p) => p?.room?._id == el?._id);
          const findSameOldRoom = self.oldRatePlanData?.rooms?.find(
            (p) => p?.room?._id == el?._id || p?._id == el?._id
          );
          // console.log(el, self.oldRatePlanData?.rooms)
          // console.log("tempRoom: ", findSameRoom, el, self.oldRatePlanData?.rooms);
          var tmpBaseRate = 0;
          var tmpExtraAdult = 0;
          var tmpExtraChild = 0;
          var tmpAdultRate = 0;
          var tmpChildRate = 0;

          tmpBaseRate =
            self.rate_value && self.rate.lowerprice
              ? Number(findSameRoom?.base_rate) *
                  ((100 - Number(self.rate.lowerprice)) / 100) || 0
              : !self.rate_value && self.rate.higherprice
              ? Number(findSameRoom?.base_rate) *
                  ((100 + Number(self.rate.higherprice)) / 100) || 0
              : Number(findSameRoom?.base_rate) || 0;
          tmpExtraAdult =
            self.rate_value && self.rate.lowerprice
              ? Number(findSameRoom?.extra_bed_adult) *
                  ((100 - Number(self.rate.lowerprice)) / 100) || 0
              : !self.rate_value && self.rate.higherprice
              ? Number(findSameRoom?.extra_bed_adult) *
                  ((100 + Number(self.rate.higherprice)) / 100) || 0
              : Number(findSameRoom?.extra_bed_adult) || 0;
          tmpExtraChild =
            self.rate_value && self.rate.lowerprice
              ? Number(findSameRoom?.extra_bed_child) *
                  ((100 - Number(self.rate.lowerprice)) / 100) || 0
              : !self.rate_value && self.rate.higherprice
              ? Number(findSameRoom?.extra_bed_child) *
                  ((100 + Number(self.rate.higherprice)) / 100) || 0
              : Number(findSameRoom?.extra_bed_child) || 0;
          tmpAdultRate =
            self.rate_value && self.rate.lowerprice
              ? Number(findSameRoom?.adult_rate) *
                  ((100 - Number(self.rate.lowerprice)) / 100) || 0
              : !self.rate_value && self.rate.higherprice
              ? Number(findSameRoom?.adult_rate) *
                  ((100 + Number(self.rate.higherprice)) / 100) || 0
              : Number(findSameRoom?.adult_rate) || 0;
          tmpChildRate =
            self.rate_value && self.rate.lowerprice
              ? Number(findSameRoom?.child_rate) *
                  ((100 - Number(self.rate.lowerprice)) / 100) || 0
              : !self.rate_value && self.rate.higherprice
              ? Number(findSameRoom?.child_rate) *
                  ((100 + Number(self.rate.higherprice)) / 100) || 0
              : Number(findSameRoom?.child_rate) || 0;

          // console.log(findSameRoom, findSameOldRoom);
          if (
            self?.rateplanID &&
            item == self.old_master_rateplan_price &&
            findSameRoom?._id &&
            findSameOldRoom?._id
          ) {
            el.check = true;
            el.base_rate = tmpBaseRate.toFixed(2);
            el.extra_bed_adult = tmpExtraAdult.toFixed(2);
            el.extra_bed_child = tmpExtraChild.toFixed(2);
            el.adult_rate = tmpAdultRate.toFixed(2);
            el.child_rate = tmpChildRate.toFixed(2);
            tmpRooms.push(el);
          } else if (self?.rateplanID && findSameRoom?._id) {
            el.check = false;
            el.base_rate = tmpBaseRate.toFixed(2);
            el.extra_bed_adult = tmpExtraAdult.toFixed(2);
            el.extra_bed_child = tmpExtraChild.toFixed(2);
            el.adult_rate = tmpAdultRate.toFixed(2);
            el.child_rate = tmpChildRate.toFixed(2);
            tmpRooms.push(el);
          } else if (!self?.rateplanID && findSameRoom?._id) {
            // el.check = true;
            el.base_rate = tmpBaseRate.toFixed(2);
            el.extra_bed_adult = tmpExtraAdult.toFixed(2);
            el.extra_bed_child = tmpExtraChild.toFixed(2);
            el.adult_rate = tmpAdultRate.toFixed(2);
            el.child_rate = tmpChildRate.toFixed(2);
            tmpRooms.push(el);
          } else {
            self.all_rooms = false;
            el.check = false;
          }
        });
        // if (self?.itemsAllRooms?.length == tmpRooms?.length)
        //   self.all_rooms = true;
        // else self.all_rooms = false;
        self.itemsAllRooms = tmpRooms;
        // // show only master rate plan room(s)
        // self.all_rooms = false;
        if (
          self.itemsAllRooms.every(function (element, index) {
            return element?.check;
          })
        ) {
          self.all_rooms = false;
          self.checkAllRooms();
        }
      } else {
        self.itemsAllRooms = self.itemsDefaultAllRooms;
      }
      // console.log("all rooms: ", self.itemsAllRooms);
    },
    initActivity(data) {
      const self = this;
      var temp = [];
      var tempActivity = [];
      var itemsData = data || self.itemsDefaultActivity;
      if (itemsData?.length) {
        itemsData?.map((el, index) => {
          tempActivity.push({
            _id: el?._id || index,
            activity: el?.name || "",
            name: el?.name || "",
            unit: el.per_guest ? "person" : "voucher",
            perprice: el.per_guest ? "guest" : "room",
            per_room: el.per_room,
            per_guest: el.per_guest,
            base_rate: el?.base_rate || null,
            extra_bed_adult: el?.extra_bed_adult || null,
            extra_bed_child: el?.extra_bed_child || null,
            adult_rate: el?.adult_rate || null,
            child_rate: el?.child_rate || null,
            hoverdel: false,
            canremove: true,
          });
          if (self?.per_room == el.per_room) {
            temp.push({
              _id: el?._id || index,
              activity: el?.name || "",
              name: el?.name || "",
              unit: el.per_guest ? "person" : "voucher",
              perprice: el.per_guest ? "guest" : "room",
              per_room: el.per_room,
              per_guest: el.per_guest,
              base_rate: el?.base_rate || null,
              extra_bed_adult: el?.extra_bed_adult || null,
              extra_bed_child: el?.extra_bed_child || null,
              adult_rate: el?.adult_rate || null,
              child_rate: el?.child_rate || null,
              hoverdel: false,
              canremove: true,
            });
          } else if (self?.per_room == false) {
            temp.push({
              _id: el?._id || index,
              activity: el?.name || "",
              name: el?.name || "",
              unit: el.per_guest ? "person" : "voucher",
              perprice: el.per_guest ? "guest" : "room",
              per_room: el.per_room,
              per_guest: el.per_guest,
              base_rate: el?.base_rate || null,
              extra_bed_adult: el?.extra_bed_adult || null,
              extra_bed_child: el?.extra_bed_child || null,
              adult_rate: el?.adult_rate || null,
              child_rate: el?.child_rate || null,
              hoverdel: false,
              canremove: true,
            });
          }
        });
      }

      tempActivity.sort(
        (a, b) => a?.activity?.localeCompare(b?.activity) || a?.activity - b?.activity
      );

      self.itemsDefaultActivity = tempActivity;
      self.itemsActivityRatePlan = tempActivity;
      // console.log('rateplanID: ', self.rateplanID)
      if (!self.rateplanID) self.addNewActivityLine();
    },
    addNewActivityLine() {
      const self = this;
      const temp = {
        _id: self?.package?.length || 0,
        name: "",
        activity: "",
        unit: "person",
        perprice: "guest",
        per_room: false,
        per_guest: true,
        base_rate: null,
        extra_bed_adult: null,
        extra_bed_child: null,
        adult_rate: null,
        child_rate: null,
        hoverdel: false,
        canremove: false,
      };
      self.package.push(temp);
    },
    checkActivityItems(item, index) {
      const self = this;
      var data = [];

      const r = self?.itemsActivityRatePlan.filter(
        (elem) =>
          elem?._id == item.activity ||
          self.package.every((member) => {
            return member.activity !== elem?._id;
          })
      );
      if (index == 0 && self.package?.length == 1) {
        data = self.itemsActivityRatePlan;
      } else data = r;

      // console.log("activity: ", data);

      return data;
    },
    async removeActivityLine(item, index) {
      const self = this;
      // EventBus.$emit("loadingtillend");
      // setTimeout(() => {
      self?.package.splice(index, 1);
      // EventBus.$emit("endloading");
      // }, 500);
    },
    selectActivity(item, index, activityId, action) {
      const self = this;
      const temp = self.itemsDefaultActivity.find((p) => p._id == activityId);
      // console.log(temp);
      item.itemsActivityUnit = [
        {
          value: "person",
          name: self.$t("RateAndInventory.add_rateplan_package3"),
        },
        {
          value: "voucher",
          name: self.$t("RateAndInventory.add_rateplan_package4"),
        },
      ];

      if (action == "activity") {
        item.adult_rate = temp?.adult_rate || null;
        item.child_rate = temp?.child_rate || null;
        item.base_rate = temp?.base_rate || null;
        item.extra_bed_adult = temp?.extra_bed_adult || null;
        item.extra_bed_child = temp?.extra_bed_child || null;
        if (temp?.per_guest) {
          item.unit = "person";
          item.per_guest = true;
          item.per_room = false;
          // item.itemsActivityUnit = [
          //   {
          //     value: "person",
          //     name: self.$t("RateAndInventory.add_rateplan_package3"),
          //   },
          // ];
        } else {
          item.unit = "voucher";
          item.per_guest = false;
          item.per_room = true;
          // item.itemsActivityUnit = [
          //   {
          //     value: "voucher",
          //     name: self.$t("RateAndInventory.add_rateplan_package4"),
          //   },
          // ];
        }
      }

      var elementActivity = document.getElementById(`activity_name_${index}`);
      var elementUnit = document.getElementById(`activity_per_${index}`);
      var elementAdultRate = document.getElementById(`activity_adultrate_${index}`);
      var elementChildRate = document.getElementById(`activity_childrate_${index}`);
      var elementBaseRate = document.getElementById(`activity_baserate_${index}`);
      var elementExtraAdult = document.getElementById(`activity_extrabedadult_${index}`);
      var elementExtraChild = document.getElementById(`activity_extrabedchild_${index}`);

      if (!item?.activity) {
        elementActivity.classList.add("border-pink-focus");
      } else {
        elementActivity?.classList?.remove("border-pink-focus");
      }
      if (!item?.unit) {
        elementUnit.classList.add("border-pink-focus");
      } else {
        elementUnit?.classList?.remove("border-pink-focus");
      }
      if (!item?.adult_rate) {
        elementAdultRate.classList.add("border-pink-focus");
      } else {
        elementAdultRate?.classList?.remove("border-pink-focus");
      }
      if (!item?.child_rate) {
        elementChildRate.classList.add("border-pink-focus");
      } else {
        elementChildRate?.classList?.remove("border-pink-focus");
      }
      if (!item?.base_rate) {
        elementBaseRate.classList.add("border-pink-focus");
      } else {
        elementBaseRate?.classList?.remove("border-pink-focus");
      }
      if (!item?.extra_bed_adult) {
        elementExtraAdult.classList.add("border-pink-focus");
      } else {
        elementExtraAdult?.classList?.remove("border-pink-focus");
      }
      if (!item?.extra_bed_child) {
        elementExtraChild.classList.add("border-pink-focus");
      } else {
        elementExtraChild?.classList?.remove("border-pink-focus");
      }
    },
    resetRate() {
      const self = this;

      if (self.rate_value) {
        self.rate.lowerprice = 10;
        self.rate.higherprice = null;
      } else if (self.rate_value == false) {
        self.rate.higherprice = 10;
        self.rate.lowerprice = null;
      } else {
        self.rate.higherprice = null;
        self.rate.lowerprice = null;
      }

      const findRatePlan = self?.itemsDefaultRatePlanPriceSelect?.find(
        (p) =>
          p?.rateplan_id == self.master_rate_plan_price ||
          p?.rateplan_id == self.master_rate_plan_price?._id
      );
      self.itemsAllRooms?.map((el) => {
        const findSameRoom = findRatePlan?.rooms?.find((p) => p?.room?._id == el?._id);

        var tmpBaseRate = 0;
        var tmpExtraAdult = 0;
        var tmpExtraChild = 0;
        var tmpAdultRate = 0;
        var tmpChildRate = 0;

        tmpBaseRate =
          self.rate_value && self.rate.lowerprice
            ? Number(findSameRoom?.base_rate) *
                ((100 - Number(self.rate.lowerprice)) / 100) || 0
            : !self.rate_value && self.rate.higherprice
            ? Number(findSameRoom?.base_rate) *
                ((100 + Number(self.rate.higherprice)) / 100) || 0
            : Number(findSameRoom?.base_rate) || 0;
        tmpExtraAdult =
          self.rate_value && self.rate.lowerprice
            ? Number(findSameRoom?.extra_bed_adult) *
                ((100 - Number(self.rate.lowerprice)) / 100) || 0
            : !self.rate_value && self.rate.higherprice
            ? Number(findSameRoom?.extra_bed_adult) *
                ((100 + Number(self.rate.higherprice)) / 100) || 0
            : Number(findSameRoom?.extra_bed_adult) || 0;
        tmpExtraChild =
          self.rate_value && self.rate.lowerprice
            ? Number(findSameRoom?.extra_bed_child) *
                ((100 - Number(self.rate.lowerprice)) / 100) || 0
            : !self.rate_value && self.rate.higherprice
            ? Number(findSameRoom?.extra_bed_child) *
                ((100 + Number(self.rate.higherprice)) / 100) || 0
            : Number(findSameRoom?.extra_bed_child) || 0;
        tmpAdultRate =
          self.rate_value && self.rate.lowerprice
            ? Number(findSameRoom?.adult_rate) *
                ((100 - Number(self.rate.lowerprice)) / 100) || 0
            : !self.rate_value && self.rate.higherprice
            ? Number(findSameRoom?.adult_rate) *
                ((100 + Number(self.rate.higherprice)) / 100) || 0
            : Number(findSameRoom?.adult_rate) || 0;
        tmpChildRate =
          self.rate_value && self.rate.lowerprice
            ? Number(findSameRoom?.child_rate) *
                ((100 - Number(self.rate.lowerprice)) / 100) || 0
            : !self.rate_value && self.rate.higherprice
            ? Number(findSameRoom?.child_rate) *
                ((100 + Number(self.rate.higherprice)) / 100) || 0
            : Number(findSameRoom?.child_rate) || 0;

        el.base_rate = tmpBaseRate.toFixed(2);
        el.extra_bed_adult = tmpExtraAdult.toFixed(2);
        el.extra_bed_child = tmpExtraChild.toFixed(2);
        el.adult_rate = tmpAdultRate.toFixed(2);
        el.child_rate = tmpChildRate.toFixed(2);
      });
    },
    modifyRateFunc() {
      const self = this;

      const findRatePlan = self?.itemsDefaultRatePlanPriceSelect?.find(
        (p) =>
          p?.rateplan_id == self.master_rate_plan_price ||
          p?.rateplan_id == self.master_rate_plan_price?._id
      );
      self.itemsAllRooms?.map((el) => {
        const findSameRoom = findRatePlan?.rooms?.find((p) => p?.room?._id == el?._id);

        var tmpBaseRate = 0;
        var tmpExtraAdult = 0;
        var tmpExtraChild = 0;
        var tmpAdultRate = 0;
        var tmpChildRate = 0;

        tmpBaseRate =
          self.rate_value && self.rate.lowerprice
            ? Number(findSameRoom?.base_rate) *
                ((100 - Number(self.rate.lowerprice)) / 100) || 0
            : !self.rate_value && self.rate.higherprice
            ? Number(findSameRoom?.base_rate) *
                ((100 + Number(self.rate.higherprice)) / 100) || 0
            : Number(findSameRoom?.base_rate) || 0;
        tmpExtraAdult =
          self.rate_value && self.rate.lowerprice
            ? Number(findSameRoom?.extra_bed_adult) *
                ((100 - Number(self.rate.lowerprice)) / 100) || 0
            : !self.rate_value && self.rate.higherprice
            ? Number(findSameRoom?.extra_bed_adult) *
                ((100 + Number(self.rate.higherprice)) / 100) || 0
            : Number(findSameRoom?.extra_bed_adult) || 0;
        tmpExtraChild =
          self.rate_value && self.rate.lowerprice
            ? Number(findSameRoom?.extra_bed_child) *
                ((100 - Number(self.rate.lowerprice)) / 100) || 0
            : !self.rate_value && self.rate.higherprice
            ? Number(findSameRoom?.extra_bed_child) *
                ((100 + Number(self.rate.higherprice)) / 100) || 0
            : Number(findSameRoom?.extra_bed_child) || 0;
        tmpAdultRate =
          self.rate_value && self.rate.lowerprice
            ? Number(findSameRoom?.adult_rate) *
                ((100 - Number(self.rate.lowerprice)) / 100) || 0
            : !self.rate_value && self.rate.higherprice
            ? Number(findSameRoom?.adult_rate) *
                ((100 + Number(self.rate.higherprice)) / 100) || 0
            : Number(findSameRoom?.adult_rate) || 0;
        tmpChildRate =
          self.rate_value && self.rate.lowerprice
            ? Number(findSameRoom?.child_rate) *
                ((100 - Number(self.rate.lowerprice)) / 100) || 0
            : !self.rate_value && self.rate.higherprice
            ? Number(findSameRoom?.child_rate) *
                ((100 + Number(self.rate.higherprice)) / 100) || 0
            : Number(findSameRoom?.child_rate) || 0;

        el.base_rate = tmpBaseRate.toFixed(2);
        el.extra_bed_adult = tmpExtraAdult.toFixed(2);
        el.extra_bed_child = tmpExtraChild.toFixed(2);
        el.adult_rate = tmpAdultRate.toFixed(2);
        el.child_rate = tmpChildRate.toFixed(2);
      });
    },
    checkAllRooms() {
      const self = this;
      self.all_rooms = !self.all_rooms;
      if (self.all_rooms) self.itemsAllRooms.map((el) => (el.check = true));
      else self.itemsAllRooms.map((el) => (el.check = false));
    },
    checkSomeRooms(item) {
      const self = this;
      var allcheck = false;
      if (item.check) {
        item.check = !item.check;
        allcheck = false;
      } else item.check = true;
      allcheck = self?.itemsAllRooms?.every((el) => el.check);
      if (!allcheck) {
        self.all_rooms = false;
        if (self.all_rooms) self.all_rooms = !self.all_rooms;
      } else self.all_rooms = true;

      // if (self?.master_rate_plan_value && self.master_rate_plan_price) {
      //   const findRatePlan = self?.itemsDefaultRatePlanPriceSelect?.find(
      //     p =>
      //       p?.rateplan_id == self.master_rate_plan_price ||
      //       p?.rateplan_id == self.master_rate_plan_price?._id
      //   );

      //   const findSameRoom = findRatePlan?.rooms?.find(
      //     p => p?.room?._id == item?._id
      //   );
      //   item.base_rate = item.check
      //     ? Number(findSameRoom?.base_rate).toFixed(2) || "0.00"
      //     : null;
      //   item.extra_bed_adult = item.check
      //     ? Number(findSameRoom?.extra_bed_adult).toFixed(2) || "0.00"
      //     : null;
      //   item.extra_bed_child = item.check
      //     ? Number(findSameRoom?.extra_bed_child).toFixed(2) || "0.00"
      //     : null;
      //   item.adult_rate = item.check
      //     ? Number(findSameRoom?.adult_rate).toFixed(2) || "0.00"
      //     : null;
      //   item.child_rate = item.check
      //     ? Number(findSameRoom?.child_rate).toFixed(2) || "0.00"
      //     : null;
      // }
    },
    checkPerRoomGuest() {
      const self = this;
      if (self.per_room) self.per_guest = false;
      else self.per_guest = true;
    },
    checkAllFood() {
      const self = this;
      self.all_food_service = !self.all_food_service;
      if (self.all_food_service) self.itemsFoodService.map((el) => (el.check = true));
      else self.itemsFoodService.map((el) => (el.check = false));
    },
    checkSomeFood(item) {
      if (item.check) item.check = !item.check;
      else item.check = true;
    },
    isEmptyOrSpaces(str) {
      return str === null || str.match(/^ *$/) !== null;
    },
    async createRatePlanPrice() {
      const self = this;

      const findRatePlan = self?.itemsDefaultRatePlanPriceSelect?.find(
        (p) =>
          p?.rateplan_id == self.master_rate_plan_price ||
          p?.rateplan_id == self.master_rate_plan_price?._id
      );

      var haveOldRatePlanRooms = [];
      var haveOldRatePlanRooms_Room = null;
      if (self.rateplanID) {
        haveOldRatePlanRooms =
          self.propertyData?.rate_plan_price?.find((p) => p._id == self.rateplanID)
            ?.rooms || [];
      }
      var tempRooms = [];
      var tempRoomsUpdate = [];
      // console.log("all rooms: ", self.itemsAllRooms);
      self.itemsAllRooms?.map((el) => {
        if (el?.check) {
          if (
            (self.per_room &&
              (el?.base_rate || el?.extra_bed_adult || el?.extra_bed_child)) ||
            (!self.per_room && (el?.adult_rate || el?.child_rate))
          ) {
            const findSameRoom = findRatePlan?.rooms?.find(
              (p) => p?.room?._id == el?._id
            );
            // console.log(
            //   "findSameRoom: ",
            //   findSameRoom,
            //   el,
            //   findRatePlan?.rooms
            // );
            var new_base_rate,
              new_extra_adult,
              new_extra_child,
              new_adult_rate,
              new_child_rate = 0;

            new_base_rate =
              self.rate_value && self.rate.lowerprice
                ? (
                    Number(findSameRoom?.base_rate) *
                    ((100 - Number(self.rate.lowerprice)) / 100)
                  ).toFixed(2) || "0.00"
                : !self.rate_value && self.rate.higherprice
                ? (
                    Number(findSameRoom?.base_rate) *
                    ((100 + Number(self.rate.higherprice)) / 100)
                  ).toFixed(2) || "0.00"
                : Number(el?.base_rate).toFixed(2) || "0.00";
            new_extra_adult =
              self.rate_value && self.rate.lowerprice
                ? (
                    Number(findSameRoom?.extra_bed_adult) *
                    ((100 - Number(self.rate.lowerprice)) / 100)
                  ).toFixed(2) || "0.00"
                : !self.rate_value && self.rate.higherprice
                ? (
                    Number(findSameRoom?.extra_bed_adult) *
                    ((100 + Number(self.rate.higherprice)) / 100)
                  ).toFixed(2) || "0.00"
                : Number(el?.extra_bed_adult).toFixed(2) || "0.00";
            new_extra_child =
              self.rate_value && self.rate.lowerprice
                ? (
                    Number(findSameRoom?.extra_bed_child) *
                    ((100 - Number(self.rate.lowerprice)) / 100)
                  ).toFixed(2) || "0.00"
                : !self.rate_value && self.rate.higherprice
                ? (
                    Number(findSameRoom?.extra_bed_child) *
                    ((100 + Number(self.rate.higherprice)) / 100)
                  ).toFixed(2) || "0.00"
                : Number(el?.extra_bed_child).toFixed(2) || "0.00";
            new_adult_rate =
              self.rate_value && self.rate.lowerprice
                ? (
                    Number(findSameRoom?.adult_rate) *
                    ((100 - Number(self.rate.lowerprice)) / 100)
                  ).toFixed(2) || "0.00"
                : !self.rate_value && self.rate.higherprice
                ? (
                    Number(findSameRoom?.adult_rate) *
                    ((100 + Number(self.rate.higherprice)) / 100)
                  ).toFixed(2) || "0.00"
                : Number(el?.adult_rate).toFixed(2) || "0.00";
            new_child_rate =
              self.rate_value && self.rate.lowerprice
                ? (
                    Number(findSameRoom?.child_rate) *
                    ((100 - Number(self.rate.lowerprice)) / 100)
                  ).toFixed(2) || "0.00"
                : !self.rate_value && self.rate.higherprice
                ? (
                    Number(findSameRoom?.child_rate) *
                    ((100 + Number(self.rate.higherprice)) / 100)
                  ).toFixed(2) || "0.00"
                : Number(el?.child_rate).toFixed(2) || "0.00";

            if (findSameRoom) {
              tempRooms.push({
                room: el._id,
                base_rate: self.per_room
                  ? Number(el?.base_rate).toFixed(2) ||
                    Number(new_base_rate).toFixed(2) ||
                    "0.00"
                  : null,
                extra_bed_adult: self.per_room
                  ? Number(el?.extra_bed_adult).toFixed(2) ||
                    Number(new_extra_adult).toFixed(2) ||
                    "0.00"
                  : null,
                extra_bed_child: self.per_room
                  ? Number(el?.extra_bed_child).toFixed(2) ||
                    Number(new_extra_child).toFixed(2) ||
                    "0.00"
                  : null,
                adult_rate: !self.per_room
                  ? Number(el?.adult_rate).toFixed(2) ||
                    Number(new_adult_rate).toFixed(2) ||
                    "0.00"
                  : null,
                child_rate: !self.per_room
                  ? Number(el?.child_rate).toFixed(2) ||
                    Number(new_child_rate).toFixed(2) ||
                    "0.00"
                  : null,
              });
              tempRoomsUpdate.push({
                ...el,
                room: el._id,
                base_rate: self.per_room
                  ? Number(el?.base_rate).toFixed(2) ||
                    Number(new_base_rate).toFixed(2) ||
                    "0.00"
                  : null,
                extra_bed_adult: self.per_room
                  ? Number(el?.extra_bed_adult).toFixed(2) ||
                    Number(new_extra_adult).toFixed(2) ||
                    "0.00"
                  : null,
                extra_bed_child: self.per_room
                  ? Number(el?.extra_bed_child).toFixed(2) ||
                    Number(new_extra_child).toFixed(2) ||
                    "0.00"
                  : null,
                adult_rate: !self.per_room
                  ? Number(el?.adult_rate).toFixed(2) ||
                    Number(new_adult_rate).toFixed(2) ||
                    "0.00"
                  : null,
                child_rate: !self.per_room
                  ? Number(el?.child_rate).toFixed(2) ||
                    Number(new_child_rate).toFixed(2) ||
                    "0.00"
                  : null,
              });
            } else {
              haveOldRatePlanRooms_Room = haveOldRatePlanRooms?.find(
                (p) => p?.room?._id == el?._id
              );
              // console.log("room: ", el, self.per_room, 'haveOldRatePlanRooms_Room: ', haveOldRatePlanRooms_Room, haveOldRatePlanRooms);
              tempRooms.push({
                room: el._id,
                base_rate: self.per_room
                  ? el?.base_rate
                    ? Number(el?.base_rate).toFixed(2) || "0.00"
                    : haveOldRatePlanRooms_Room?.base_rate
                    ? Number(haveOldRatePlanRooms_Room?.base_rate).toFixed(2) || "0.00"
                    : 0
                  : null,
                extra_bed_adult: self.per_room
                  ? el?.extra_bed_adult
                    ? Number(el?.extra_bed_adult).toFixed(2) || "0.00"
                    : haveOldRatePlanRooms_Room?.extra_bed_adult
                    ? Number(haveOldRatePlanRooms_Room?.extra_bed_adult).toFixed(2) ||
                      "0.00"
                    : 0
                  : null,
                extra_bed_child: self.per_room
                  ? el?.extra_bed_child
                    ? Number(el?.extra_bed_child).toFixed(2) || "0.00"
                    : haveOldRatePlanRooms_Room?.extra_bed_child
                    ? Number(haveOldRatePlanRooms_Room?.extra_bed_child).toFixed(2) ||
                      "0.00"
                    : 0
                  : null,
                adult_rate: !self.per_room
                  ? el?.adult_rate
                    ? Number(el?.adult_rate).toFixed(2) || "0.00"
                    : haveOldRatePlanRooms_Room?.adult_rate
                    ? Number(haveOldRatePlanRooms_Room?.adult_rate).toFixed(2) || "0.00"
                    : 0
                  : null,
                child_rate: !self.per_room
                  ? el?.child_rate
                    ? Number(el?.child_rate).toFixed(2) || "0.00"
                    : haveOldRatePlanRooms_Room?.child_rate
                    ? Number(haveOldRatePlanRooms_Room?.child_rate).toFixed(2) || "0.00"
                    : 0
                  : null,
              });
              tempRoomsUpdate.push({
                ...el,
                room: el._id,
                base_rate: self.per_room
                  ? el?.base_rate
                    ? Number(el?.base_rate).toFixed(2) || "0.00"
                    : haveOldRatePlanRooms_Room?.base_rate
                    ? Number(haveOldRatePlanRooms_Room?.base_rate).toFixed(2) || "0.00"
                    : 0
                  : null,
                extra_bed_adult: self.per_room
                  ? el?.extra_bed_adult
                    ? Number(el?.extra_bed_adult).toFixed(2) || "0.00"
                    : haveOldRatePlanRooms_Room?.extra_bed_adult
                    ? Number(haveOldRatePlanRooms_Room?.extra_bed_adult).toFixed(2) ||
                      "0.00"
                    : 0
                  : null,
                extra_bed_child: self.per_room
                  ? el?.extra_bed_child
                    ? Number(el?.extra_bed_child).toFixed(2) || "0.00"
                    : haveOldRatePlanRooms_Room?.extra_bed_child
                    ? Number(haveOldRatePlanRooms_Room?.extra_bed_child).toFixed(2) ||
                      "0.00"
                    : 0
                  : null,
                adult_rate: !self.per_room
                  ? el?.adult_rate
                    ? Number(el?.adult_rate).toFixed(2) || "0.00"
                    : haveOldRatePlanRooms_Room?.adult_rate
                    ? Number(haveOldRatePlanRooms_Room?.adult_rate).toFixed(2) || "0.00"
                    : 0
                  : null,
                child_rate: !self.per_room
                  ? el?.child_rate
                    ? Number(el?.child_rate).toFixed(2) || "0.00"
                    : haveOldRatePlanRooms_Room?.child_rate
                    ? Number(haveOldRatePlanRooms_Room?.child_rate).toFixed(2) || "0.00"
                    : 0
                  : null,
              });
            }
          } else {
            el.check = false;
            swal(
              self.$t("Alert.warn_title"),
              `${self.$t("RateAndInventory.plese_fill_price")}`,
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          }
        }
      });

      var tempPackage = [];
      if (self.package_value)
        self.package?.map((el) => {
          if (el.activity) {
            tempPackage.push({
              activity: el.activity,
              unit: el?.per_guest ? "person" : "voucher", // person or voucher
              child_rate: el?.child_rate || 0,
              adult_rate: el?.adult_rate || 0,
              base_rate: el?.base_rate || 0,
              extra_bed_adult: el?.extra_bed_adult || 0,
              extra_bed_child: el?.extra_bed_child || 0,
            });
          }
        });

      var tempHoliday = {
        weekend: 0,
        holiday: 0,
        special_holiday: 0,
      };

      var tempSeason = [];
      self?.season_add_price?.map((el) => {
        if (self.season_add_price_value)
          tempSeason.push({
            seasonal: el.seasonal,
            increase_price: el.increase_price,
          });
      });

      const obj = {
        rate_plan: self.rate_plan || null,
        master_rate_plan_price: self.master_rate_plan_value
          ? self.master_rate_plan_price || null
          : null,
        name: self.name,
        per_room: self.per_room,
        per_guest: !self.per_room,
        rooms: tempRooms,
        advance_booking: self.advance_booking,
        policy_change_date: self.change_date || undefined,
        privileges: self.privileges,
        food_service: self.food_service ? self.food_service_select : null,
        package: self.package_value ? tempPackage : [],
        minimum_stay: self.minimum_stay,
        age_of_voucher: self.age_of_voucher,
        rate: {
          enum: self.rate_value
            ? self.rate.lowerprice || null
            : self.rate.higherprice || null,
          type: self.rate_value ? "decrease" : "increase",
          unit: "percent",
        },
        holiday_booking_fee: self.holiday_booking_fee_value
          ? self.holiday_booking_fee
          : tempHoliday,
        seasonal_increase_price: tempSeason,
        type: "custom",
        status: self.rateplanStatus,
        is_signup: self.isSignupValue,
      };

      var rateplanIndex = 0;
      var tmpRooms = [];

      // console.log("obj: ", obj);

      EventBus.$emit("loadingtillend");
      if (!obj?.name || self.isEmptyOrSpaces(obj?.name)) {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefill")} ${self.$t("RateAndInventory.rateplan_name")}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (self.food_service && !self.food_service_select) {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleaseselect")} ${self.$t("RateAndInventory.food_select")}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (self.package_value && !tempPackage?.length) {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleaseselect")} ${self.$t(
            "RateAndInventory.package_select"
          )}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (self.minimum_stay_value && !self.minimum_stay) {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleaseselect")} ${self.$t("RateAndInventory.rest_less")}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (self.master_rate_plan_value && !self.master_rate_plan_price) {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleaseselect")} ${self.$t(
            "RateAndInventory.master_rate_plan"
          )}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (
        self.master_rate_plan_value &&
        self.master_rate_plan_price &&
        !obj.rate.enum
      ) {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefill")} ${self.$t(
            "RateAndInventory.master_rate_plan_enum"
          )}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (!obj?.rooms?.length) {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("RateAndInventory.plese_select_rooms")}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (
        (obj?.name && obj?.rooms?.length) ||
        (self.minimum_stay_value && obj?.minimum_stay) ||
        obj?.age_of_voucher?.length ||
        obj?.age_of_voucher?.expire_date
      ) {
        if (
          self.holiday_booking_fee_value &&
          obj?.holiday_booking_fee?.weekend &&
          obj?.holiday_booking_fee?.holiday &&
          obj?.holiday_booking_fee?.special_holiday
        ) {
          if (self.rateplanID) {
            try {
              const res = await self.axios.put(
                process.env.VUE_APP_API +
                  `/property/rate_plan_price?rate_plan_price_id=${self?.rateplanID}`,
                obj,
                {
                  headers: {
                    Authorization: self?.userToken,
                    "X-Language-Code":
                      localStorage?.getItem("language") || self?.$store?.state?.language,
                  },
                }
              );
              if (res.status == 200) {
                self.saveSuccess = true;
                self.itemsDefaultRatePlanPrice?.map(async (rateplan) => {
                  rateplanIndex = rateplanIndex + 1;
                  if (
                    rateplan?.master_rate_plan_price == self.rateplanID ||
                    rateplan?.master_rate_plan_price?._id == self.rateplanID
                  ) {
                    tmpRooms = [];
                    tempRooms?.map((room) => {
                      const findSameRoom = rateplan?.rooms?.find(
                        (p) =>
                          p?.room?._id == room?.room ||
                          p?.room?.value == room?.room_name?.value
                      );
                      var tmpBaseRate = 0;
                      var tmpExtraAdult = 0;
                      var tmpExtraChild = 0;
                      var tmpAdultRate = 0;
                      var tmpChildRate = 0;

                      tmpBaseRate =
                        rateplan.rate?.enum && rateplan.rate?.type == "decrease"
                          ? Number(room?.base_rate) *
                              ((100 - Number(rateplan.rate?.enum)) / 100) || 0
                          : rateplan.rate?.enum && rateplan.rate?.type == "increase"
                          ? Number(room?.base_rate) *
                              ((100 + Number(rateplan.rate?.enum)) / 100) || 0
                          : Number(room?.base_rate) || 0;
                      tmpExtraAdult =
                        rateplan.rate?.enum && rateplan.rate?.type == "decrease"
                          ? Number(room?.extra_bed_adult) *
                              ((100 - Number(rateplan.rate?.enum)) / 100) || 0
                          : rateplan.rate?.enum && rateplan.rate?.type == "increase"
                          ? Number(room?.extra_bed_adult) *
                              ((100 + Number(rateplan.rate?.enum)) / 100) || 0
                          : Number(room?.extra_bed_adult) || 0;
                      tmpExtraChild =
                        rateplan.rate?.enum && rateplan.rate?.type == "decrease"
                          ? Number(room?.extra_bed_child) *
                              ((100 - Number(rateplan.rate?.enum)) / 100) || 0
                          : rateplan.rate?.enum && rateplan.rate?.type == "increase"
                          ? Number(room?.extra_bed_child) *
                              ((100 + Number(rateplan.rate?.enum)) / 100) || 0
                          : Number(room?.extra_bed_child) || 0;
                      tmpAdultRate =
                        rateplan.rate?.enum && rateplan.rate?.type == "decrease"
                          ? Number(room?.adult_rate) *
                              ((100 - Number(rateplan.rate?.enum)) / 100) || 0
                          : rateplan.rate?.enum && rateplan.rate?.type == "increase"
                          ? Number(room?.adult_rate) *
                              ((100 + Number(rateplan.rate?.enum)) / 100) || 0
                          : Number(room?.adult_rate) || 0;
                      tmpChildRate =
                        rateplan.rate?.enum && rateplan.rate?.type == "decrease"
                          ? Number(room?.child_rate) *
                              ((100 - Number(rateplan.rate?.enum)) / 100) || 0
                          : rateplan.rate?.enum && rateplan.rate?.type == "increase"
                          ? Number(room?.child_rate) *
                              ((100 + Number(rateplan.rate?.enum)) / 100) || 0
                          : Number(room?.child_rate) || 0;

                      if (findSameRoom?._id || rateplan?.rooms?.length < 1)
                        tmpRooms.push({
                          room: findSameRoom?._id || room?.room,
                          base_rate: tmpBaseRate.toFixed(2),
                          extra_bed_adult: tmpExtraAdult.toFixed(2),
                          extra_bed_child: tmpExtraChild.toFixed(2),
                          adult_rate: tmpAdultRate.toFixed(2),
                          child_rate: tmpChildRate.toFixed(2),
                        });
                    });
                    const objUpdate = {
                      per_room: obj?.per_room,
                      per_guest: obj?.per_guest,
                      rooms: tmpRooms,
                    };
                    // console.log(rateplan, tmpRooms, objUpdate);

                    try {
                      const res = await self.axios.put(
                        process.env.VUE_APP_API +
                          `/property/rate_plan_price?rate_plan_price_id=${rateplan?._id}`,
                        objUpdate,
                        {
                          headers: {
                            Authorization: self?.userToken,
                            "X-Language-Code":
                              localStorage?.getItem("language") ||
                              self?.$store?.state?.language,
                          },
                        }
                      );
                    } catch (error) {
                      EventBus.$emit("endloading");
                      console.log(error);
                    }
                  }
                });
                if (self.itemsDefaultRatePlanPrice?.length == rateplanIndex)
                  swal(
                    self.$t("Alert.success_title"),
                    self.$t("Alert.save_success"),
                    self.$t("Alert.success_label"),
                    {
                      button: false,
                      timer: 3000,
                    }
                  ).then(() => self.gotoLink("SigninRatePlanScreen", null));
              }
            } catch (error) {
              EventBus.$emit("endloading");
              console.log(error);
              swal(
                self.$t("Alert.error_title"),
                error?.response?.data?.message || self.$t("Alert.tryagain"),
                self.$t("Alert.error_label"),
                {
                  button: false,
                  timer: 3000,
                }
              );
            } finally {
              EventBus.$emit("endloading");
            }
          } else {
            try {
              const res = await self.axios.post(
                process.env.VUE_APP_API +
                  `/property/rate_plan_price?property_id=${self?.propertyID}`,
                obj,
                {
                  headers: {
                    Authorization: self?.userToken,
                    "X-Language-Code":
                      localStorage?.getItem("language") || self?.$store?.state?.language,
                  },
                }
              );
              if (res.status == 200) {
                self.saveSuccess = true;
                swal(
                  self.$t("Alert.success_title"),
                  self.$t("Alert.add_new_success"),
                  self.$t("Alert.success_label"),
                  {
                    button: false,
                    timer: 3000,
                  }
                ).then(() => self.gotoLink("SigninRatePlanScreen", null));
              }
            } catch (error) {
              EventBus.$emit("endloading");
              console.log(error);
              swal(
                self.$t("Alert.error_title"),
                error?.response?.data?.message || self.$t("Alert.tryagain"),
                self.$t("Alert.error_label"),
                {
                  button: false,
                  timer: 3000,
                }
              );
            } finally {
              EventBus.$emit("endloading");
            }
          }
        } else if (
          self.holiday_booking_fee_value &&
          !obj?.holiday_booking_fee?.weekend &&
          !obj?.holiday_booking_fee?.holiday &&
          !obj?.holiday_booking_fee?.special_holiday
        ) {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.fillinfo"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } else {
          if (self.rateplanID) {
            try {
              const res = await self.axios.put(
                process.env.VUE_APP_API +
                  `/property/rate_plan_price?rate_plan_price_id=${self?.rateplanID}`,
                obj,
                {
                  headers: {
                    Authorization: self?.userToken,
                    "X-Language-Code":
                      localStorage?.getItem("language") || self?.$store?.state?.language,
                  },
                }
              );
              if (res.status == 200) {
                self.saveSuccess = true;
                self.itemsDefaultRatePlanPrice?.map(async (rateplan) => {
                  rateplanIndex = rateplanIndex + 1;
                  if (
                    rateplan?.master_rate_plan_price == self.rateplanID ||
                    rateplan?.master_rate_plan_price?._id == self.rateplanID
                  ) {
                    // console.log(rateplan);
                    tmpRooms = [];

                    // const findHaveSameRoom = rateplan?.rooms.filter(object1 => {
                    //   return tempRoomsUpdate.some(object2 => {
                    //     console.log(object1, object2)
                    //     return (
                    //       object1.room?._id == object2.room ||
                    //       object1.room?.value == object2.room_name?.value
                    //     );
                    //   });
                    // });
                    tempRoomsUpdate?.map((room) => {
                      const findSameRoom = rateplan?.rooms?.find(
                        (p) =>
                          p?.room?._id == room?.room ||
                          p?.room?.value == room?.room_name?.value
                      );
                      // console.log(findSameRoom, findHaveSameRoom);
                      var tmpBaseRate = 0;
                      var tmpExtraAdult = 0;
                      var tmpExtraChild = 0;
                      var tmpAdultRate = 0;
                      var tmpChildRate = 0;

                      tmpBaseRate =
                        rateplan.rate?.enum && rateplan.rate?.type == "decrease"
                          ? Number(room?.base_rate) *
                              ((100 - Number(rateplan.rate?.enum)) / 100) || 0
                          : rateplan.rate?.enum && rateplan.rate?.type == "increase"
                          ? Number(room?.base_rate) *
                              ((100 + Number(rateplan.rate?.enum)) / 100) || 0
                          : Number(room?.base_rate) || 0;
                      tmpExtraAdult =
                        rateplan.rate?.enum && rateplan.rate?.type == "decrease"
                          ? Number(room?.extra_bed_adult) *
                              ((100 - Number(rateplan.rate?.enum)) / 100) || 0
                          : rateplan.rate?.enum && rateplan.rate?.type == "increase"
                          ? Number(room?.extra_bed_adult) *
                              ((100 + Number(rateplan.rate?.enum)) / 100) || 0
                          : Number(room?.extra_bed_adult) || 0;
                      tmpExtraChild =
                        rateplan.rate?.enum && rateplan.rate?.type == "decrease"
                          ? Number(room?.extra_bed_child) *
                              ((100 - Number(rateplan.rate?.enum)) / 100) || 0
                          : rateplan.rate?.enum && rateplan.rate?.type == "increase"
                          ? Number(room?.extra_bed_child) *
                              ((100 + Number(rateplan.rate?.enum)) / 100) || 0
                          : Number(room?.extra_bed_child) || 0;
                      tmpAdultRate =
                        rateplan.rate?.enum && rateplan.rate?.type == "decrease"
                          ? Number(room?.adult_rate) *
                              ((100 - Number(rateplan.rate?.enum)) / 100) || 0
                          : rateplan.rate?.enum && rateplan.rate?.type == "increase"
                          ? Number(room?.adult_rate) *
                              ((100 + Number(rateplan.rate?.enum)) / 100) || 0
                          : Number(room?.adult_rate) || 0;
                      tmpChildRate =
                        rateplan.rate?.enum && rateplan.rate?.type == "decrease"
                          ? Number(room?.child_rate) *
                              ((100 - Number(rateplan.rate?.enum)) / 100) || 0
                          : rateplan.rate?.enum && rateplan.rate?.type == "increase"
                          ? Number(room?.child_rate) *
                              ((100 + Number(rateplan.rate?.enum)) / 100) || 0
                          : Number(room?.child_rate) || 0;

                      if (findSameRoom?._id || rateplan?.rooms?.length < 1)
                        tmpRooms.push({
                          room: room?._id || room?.room,
                          base_rate: tmpBaseRate.toFixed(2),
                          extra_bed_adult: tmpExtraAdult.toFixed(2),
                          extra_bed_child: tmpExtraChild.toFixed(2),
                          adult_rate: tmpAdultRate.toFixed(2),
                          child_rate: tmpChildRate.toFixed(2),
                        });
                    });
                    const objUpdate = {
                      per_room: obj?.per_room,
                      per_guest: obj?.per_guest,
                      rooms: tmpRooms,
                    };
                    // console.log(tmpRooms, objUpdate);

                    try {
                      const res = await self.axios.put(
                        process.env.VUE_APP_API +
                          `/property/rate_plan_price?rate_plan_price_id=${rateplan?._id}`,
                        objUpdate,
                        {
                          headers: {
                            Authorization: self?.userToken,
                            "X-Language-Code":
                              localStorage?.getItem("language") ||
                              self?.$store?.state?.language,
                          },
                        }
                      );
                    } catch (error) {
                      EventBus.$emit("endloading");
                      console.log(error);
                    }
                  }
                });
                if (self.itemsDefaultRatePlanPrice?.length == rateplanIndex)
                  swal(
                    self.$t("Alert.success_title"),
                    self.$t("Alert.save_success"),
                    self.$t("Alert.success_label"),
                    {
                      button: false,
                      timer: 3000,
                    }
                  ).then(() => self.gotoLink("SigninRatePlanScreen", null));
              }
            } catch (error) {
              EventBus.$emit("endloading");
              console.log(error);
              swal(
                self.$t("Alert.error_title"),
                error?.response?.data?.message || self.$t("Alert.tryagain"),
                self.$t("Alert.error_label"),
                {
                  button: false,
                  timer: 3000,
                }
              );
            } finally {
              EventBus.$emit("endloading");
            }
          } else {
            try {
              const res = await self.axios.post(
                process.env.VUE_APP_API +
                  `/property/rate_plan_price?property_id=${self?.propertyID}`,
                obj,
                {
                  headers: {
                    Authorization: self?.userToken,
                    "X-Language-Code":
                      localStorage?.getItem("language") || self?.$store?.state?.language,
                  },
                }
              );
              if (res.status == 200) {
                self.saveSuccess = true;
                swal(
                  self.$t("Alert.success_title"),
                  self.$t("Alert.add_new_success"),
                  self.$t("Alert.success_label"),
                  {
                    button: false,
                    timer: 3000,
                  }
                ).then(() => self.gotoLink("SigninRatePlanScreen", null));
              }
            } catch (error) {
              EventBus.$emit("endloading");
              console.log(error);
              swal(
                self.$t("Alert.error_title"),
                error?.response?.data?.message || self.$t("Alert.tryagain"),
                self.$t("Alert.error_label"),
                {
                  button: false,
                  timer: 3000,
                }
              );
            } finally {
              EventBus.$emit("endloading");
            }
          }
        }
      } else {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    checkChangeData() {
      const self = this;
      var cantgonextroute = true;

      var tempRooms = [];
      self.itemsAllRooms?.map((el) => {
        if (el?.check) {
          tempRooms.push(el._id);
        }
      });
      tempRooms.sort();

      var tempOldRooms = [];
      self.oldRatePlanData?.rooms?.map((el) => {
        if (el?.room?._id || el?._id) {
          tempOldRooms.push(el?.room?._id || el?._id);
        }
      });
      tempOldRooms.sort();

      var isSameRooms =
        tempOldRooms?.length == tempRooms?.length &&
        tempOldRooms?.every(function (element, index) {
          return (
            element?.base_rate == tempRooms[index]?.base_rate &&
            element?.extra_bed_adult == tempRooms[index]?.extra_bed_adult &&
            element?.extra_bed_child == tempRooms[index]?.extra_bed_child &&
            element?.adult_rate == tempRooms[index]?.adult_rate &&
            element?.child_rate == tempRooms[index]?.child_rate
          );
        });

      var tempPackage = [];
      self.package?.map((el) => {
        if (el.activity) tempPackage.push(el.activity);
      });
      tempPackage.sort();

      var tempOldPackage = [];
      self.oldRatePlanData?.package?.map((el) => {
        tempOldPackage.push(el.activity?._id);
      });
      tempOldPackage.sort();

      var isSamePackages =
        tempOldPackage?.length == tempPackage?.length &&
        tempOldPackage?.every(function (element, index) {
          return element === tempPackage[index];
        });

      var tempSeason = [];
      self?.season_add_price?.map((el) => {
        if (el.seasonal && el.increase_price) tempSeason.push(el.seasonal);
      });
      tempSeason.sort();

      var tempOldSeason = [];
      self?.oldRatePlanData?.seasonal_increase_price?.map((el) => {
        if (el.seasonal?._id && el.increase_price) tempOldSeason.push(el.seasonal?._id);
      });
      tempOldSeason.sort();

      var isSameSeason =
        tempOldSeason?.length == tempSeason?.length &&
        tempOldSeason?.every((element, index) => {
          return element === tempSeason[index];
        });

      if (self.rateplanID) {
        // console.log("old: ", self.oldRatePlanData);
        // console.log("name: ", self.oldRatePlanData.name, self.name);
        // console.log(
        //   "advance: ",
        //   self.oldRatePlanData.advance_booking,
        //   self.advance_booking
        // );
        // console.log(
        //   "policy change date: ",
        //   self.oldRatePlanData.policy_change_date,
        //   self.change_date
        // );
        // console.log(
        //   "privileges: ",
        //   self.oldRatePlanData.privileges,
        //   self.privileges
        // );
        // console.log(
        //   "food service: ",
        //   self.oldRatePlanData.food_service,
        //   self.food_service_select
        // );
        // console.log(
        //   "package: ",
        //   self.oldRatePlanData.package,
        //   self.package,
        //   isSamePackages
        // );
        // console.log(
        //   "minimum stay: ",
        //   self.oldRatePlanData.minimum_stay,
        //   self.minimum_stay,
        //   self.minimum_stay_value
        // );
        // console.log("per room: ", self.oldRatePlanData.per_room, self.per_room);
        // console.log(
        //   "per guest: ",
        //   self.oldRatePlanData.per_guest,
        //   self.per_guest
        // );
        // console.log(
        //   "rooms: ",
        //   self.oldRatePlanData.rooms,
        //   self.itemsAllRooms,
        //   isSameRooms
        // );
        // console.log(
        //   "age of voucher: ",
        //   self.oldRatePlanData.age_of_voucher,
        //   self.age_of_voucher
        // );
        // console.log(
        //   "holiday: ",
        //   self.oldRatePlanData.holiday_booking_fee,
        //   self.holiday_booking_fee
        // );
        // console.log(
        //   "seasonal: ",
        //   self.oldRatePlanData.seasonal_increase_price,
        //   self.season_add_price
        // );

        // console.log("rate: ", self?.oldRatePlanData?.rate, self?.rate);

        if (self?.saveSuccess) {
          cantgonextroute = false;
        } else if (
          self?.name !==
            (self.oldRatePlanData?.name || self.oldRatePlanData?.rate_plan?.value) ||
          !isSameRooms ||
          self?.advance_booking !== self.oldRatePlanData?.advance_booking ||
          self?.per_room !== self?.oldRatePlanData?.per_room ||
          self?.per_guest !== self?.oldRatePlanData?.per_guest ||
          (self?.change_date &&
            self?.change_date !== self?.oldRatePlanData?.policy_change_date?._id) ||
          self?.privileges !== self?.oldRatePlanData?.privileges ||
          (self.food_service &&
            self?.food_service_select !== self?.oldRatePlanData?.food_service) ||
          !isSamePackages ||
          self.minimum_stay !== self?.oldRatePlanData?.minimum_stay ||
          (self?.age_of_voucher &&
            self?.age_of_voucher?.length !==
              self?.oldRatePlanData?.age_of_voucher?.length) ||
          (self?.age_of_voucher?.expire_date &&
            self?.age_of_voucher?.expire_date !==
              self?.oldRatePlanData?.age_of_voucher?.expire_date) ||
          (self?.oldRatePlanData?.rate?.type == "increase" &&
            self?.oldRatePlanData?.rate?.enum &&
            self?.rate?.higherprice !== self?.oldRatePlanData?.rate?.enum) ||
          (self?.oldRatePlanData?.rate?.type == "decrease" &&
            self?.oldRatePlanData?.rate?.enum &&
            self?.rate?.lowerprice !== self?.oldRatePlanData?.rate?.enum) ||
          self?.holiday_booking_fee?.weekend !==
            self?.oldRatePlanData?.holiday_booking_fee?.weekend ||
          !isSameSeason
        ) {
          cantgonextroute = true;
        } else {
          cantgonextroute = false;
        }
      } else {
        // console.log("name: ", self.name);
        // console.log("rooms: ", tempRooms);
        // console.log("change date: ", self.change_date);
        // console.log("minimum stay: ", self.minimum_stay);
        // console.log("age_of_voucher: ", self.age_of_voucher);
        // console.log("holiday: ", self.holiday_booking_fee_value);

        if (self?.saveSuccess) {
          cantgonextroute = false;
        } else if (
          self?.name ||
          tempRooms?.length ||
          (self?.advance_booking &&
            self?.advance_booking !== self?.propertyData?.book_in_advance) ||
          self?.change_date ||
          self?.minimum_stay ||
          (self?.age_of_voucher?.length && self?.age_of_voucher?.length !== 365) ||
          self?.age_of_voucher?.expire_date
        ) {
          if (
            self.holiday_booking_fee_value ||
            self.holiday_booking_fee?.weekend ||
            self.holiday_booking_fee?.holiday ||
            self.holiday_booking_fee?.special_holiday
          ) {
            cantgonextroute = true;
          } else {
            cantgonextroute = true;
          }
        } else {
          cantgonextroute = false;
        }
      }
      return cantgonextroute;
    },
    checkVoucherFunc() {
      const self = this;
      if (self.age_of_voucher_value) {
        self.age_of_voucher = {
          length: null,
          expire_date: moment().add(6, "month").format("YYYY-MM-DD"),
        };
        self.minDateAgeOfVoucherExpireDate = moment()
          .add(6, "month")
          .format("YYYY-MM-DD");
      } else if (self.age_of_voucher_value == false)
        self.age_of_voucher = {
          length: 365,
          expire_date: null,
        };
      else
        self.age_of_voucher = {
          length: null,
          expire_date: null,
        };
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/rateplan.css";
@import "../../../../styles/signin/timeline.css";
</style>

<style lang="scss" scoped>
.border-pink-focus {
  // background-color: #fff;
  border-color: var(--pink) !important;
}
</style>
